.input {
  background: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  padding: 7px 14px;
  color: var(--color-input-text);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px;
  line-height: 18px;
}

.input input {
  display: flex;
  align-items: center;
  color: var(--color-primary);
}

.wrapInput {
  display: flex;
}

.input:global(.ant-input-affix-wrapper-disabled) {
  background: var(--color-light-4);
}

.input:disabled {
  color: var(--color-dark);
  padding-right: 30px;
  background-color: transparent;
}

.icon {
  font-size: 1.6rem;
  position: absolute;
  right: 11px;
  top: 17px;
}

.input:global(.ant-input) {
  border: 1px solid var(--color-light-2);
}

.input:global(.ant-input-affix-wrapper-disabled) {
  border: 1px solid var(--color-light-2);
  box-shadow: none;
}


@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .input input {
    font-size: 20px;
  }

  .input {
    font-size: 20px;
  }

  .icon {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .input {
    font-size: 15px;
  }

  .icon {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {

  .input {
    font-size: 10px;
  }

  .input:disabled {
    padding-right: 25px;
  }

  .icon {
    font-size: 0.8rem;
    top:12px;
  }

}