.wrapperNotification {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notification {
  border: 1px solid var(--color-primary);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  width: 419px;
  padding: 10px 17px 10px 16px;
  margin: 0;
}

.notification :global(.anticon.ant-notification-notice-icon-success) {
  display: none;
}

.notification :global(.anticon.ant-notification-notice-icon-error) {
  display: none;
}

.notification :global(.ant-notification-notice-message) {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 25px;
  margin: 0;
  padding: 0;
}

.notification :global(.ant-notification-notice-close) {
  display: none;
}

.iconNotification {
  font-size: 2.5rem;
  margin-right: 25px;
}

@media screen and (max-width: 2559px) and (min-width: 1949px) {
  .notification {
    width: 350px;
    padding: 8px 14px 8px 14px;
  }

  .notification :global(.ant-notification-notice-message) {
    font-size: 23px;
  }

  .iconNotification {
    font-size: 2rem;
    margin-right: 20px;
  }
}

@media screen and (max-width: 1950px) and (min-width: 1500px) {
  .notification {
    width: 330px;
  }

  .notification :global(.ant-notification-notice-message) {
    font-size: 15px;
  }

  .iconNotification {
    font-size: 1.8rem;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .notification {
    width: 160px;
    padding: 5px 10px 5px 10px;
  }

  .notification :global(.ant-notification-notice-message) {
    font-size: 10px;
    line-height: 13px;
  }

  .iconNotification {
    font-size: 1.2rem;
    margin-right: 7px;
  }
}
