.wrapperList {
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 30px;
  width: 100%;
  height: 470px;
  background: var(--color-light);
  margin-bottom: 50px;
  padding: 30px 50px;
  color:var(--color-primary);
}

.wrapMto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 50px;
}

.aboutRapart {
  background: var(--color-light);
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 30px;
  width: 60%;
  padding: 40px;
  color:var(--color-primary);
}

.subTitleRapart {
  color: var(--color-dark);
}

.title {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 30px;
}

.contacts {
  position: relative;
  margin-bottom: 20px;
}

.elementList {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  padding-bottom: 35px;
  /*width: 50%;*/
  display: flex;
  align-items: center;
}

.itemTitle {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 35px;
}

.itemSubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: var(--color-dark);
}

.list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 90%;
  list-style: none;
  padding: 0;
}

.elementList::before {
  content: "• ";
  color: var(--color-dark);
  font-size: 40px;
  margin-right: 15px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .img {
    width: 580px;
  }

  .wrapMto {
    margin-bottom: 42px;
    font-size: 22px;
    line-height: 40px;
  }

  .aboutRapart {
    padding: 25px;
  }

  .title {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .elementList {
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 20px;
  }

  .wrapperList {
    height: 310px;
    padding: 15px 25px;
    margin-bottom: 15px;
  }

  .itemTitle {
    font-size: 22px;
    line-height: 30px;
  }

  .itemSubTitle {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .img {
    width: 480px;
  }

  .wrapMto {
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 35px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .elementList {
    font-size: 21px;
    width: 50%;
    line-height: 30px;
    padding-bottom: 20px;
    white-space: nowrap;
  }

  .wrapperList {
    min-height: 280px;
    max-height: 340px;
    padding: 15px 25px;
    margin-bottom: 30px;
  }

  .itemTitle {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
    white-space: initial;
  }

  .itemSubTitle {
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    white-space: initial;
  }

  .elementList::before {
    margin-right: 10px;
  }

  .aboutRapart {
    padding: 25px;
  }
}


@media screen and (max-width: 1650px) and (min-width: 1500px) {
  .wrapperList {
    min-height: 280px;
    max-height: 380px;
  }

  .aboutRapart {
 width: 55%;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .img {
    width: 300px;
  }

  .wrapMto {
    margin-bottom: 33px;
    font-size: 11px;
    line-height: 20px;
  }

  .aboutRapart {
    box-shadow: 2px 2px 10px var(--color-light-4);
    border-radius: 15px;
    padding: 20px;
  }

  .title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .elementList {
    font-size: 12px;
    line-height: 25px;
    padding-bottom: 8px;
    white-space: nowrap;
  }

  .wrapperList {
    height: 165px;
    padding: 10px;
    margin-bottom: 25px;
    border-radius: 15px;
  }

  .itemTitle {
    font-size: 12px;
    line-height: 16px;
  }

  .itemSubTitle {
    font-size: 11px;
    line-height: 16px;
  }

  .elementList::before {
    margin-right: 10px;
    font-size: 20px;
  }
}