.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.buttons {
  margin-top: 60px;
  gap: 0.5rem;
}

.modalText{
  font-size: 17px;
  font-family: var(--main-font-family);
}

.button {
  background-color: var(--color-primary);
  width: auto;
  min-width: 209px;
  height: 60px;
  box-shadow: 2px 2px 10px var(--color-light-4);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 10px;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 18px;
  color: var(--color-button-text);
  font-family: var(--main-font-family);
}

.buttonDifferentLabel {
  color: var(--color-dark);
}

.iconButton {
  font-size: 2rem;
  margin-right: 10px;
  display: flex;
}

.buttonDifferent {
  background: var(--color-light);
  border: 1px solid var(--color-input-text);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  height: 60px;
  padding: 15px 14px 15px 14px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px !important;
  margin-right: 20px;
  cursor: pointer;
}

.button:hover {
  background:  var(--color-primary);
  color: var(--color-button-text);
}

.button:focus {
  background: var(--color-primary);
  color: var(--color-button-text);
}

.button:active {
  box-shadow: none;
}

.button_label {
  color: var(--color-button-text);
  text-align: center;
}

.disabled, .disabled:hover, .disabled:active {
  background-color: var(--color-dark-2);

}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .button {
    height: 50px;
    font-size: 20px;
  }

  .buttonDifferent {
    height: 45px;
    font-size: 15px;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .button {
    min-width: 170px;
    height: 40px;
    font-size: 16px;
  }

  .iconButton {
    font-size: 1.6rem;
  }

  .buttonDifferent {
    font-size: 16px;
    height: 35px;
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .button {
    min-width: 105px;
    height: 30px;
    font-size: 10px;
  }

  .iconButton {
    font-size: 0.9rem;
    margin-top: 2px;
  }

  .buttonDifferent {
    font-size: 10px;
    height: 30px;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
    padding: 8px 9px 8px 9px;
    border-radius: 5px;
    margin-bottom: 10px !important;
  }

  .button_label {
    line-height: 10px;
    text-align: center;
  }

  .container{
    gap: 5px;
  }
}
