.tooltipArray {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.tooltipArray div {
  margin-right: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.tooltipWordBreak :global(.ant-tooltip-inner) {
  word-break: break-all;
  max-width: 600px !important;
}

.hiddenTooltip {
  display: none;
}

.back {
  font-size: 1.25rem;
  cursor: pointer;
  display: inline;
}

.wrapTable {
  /* height: calc(100vh - 38%) */
  height: calc(100% - 40px);
  padding: 0px 40px 0px 40px;
}

.back-title {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
  vertical-align: middle;
}

.search-wrap {
  margin-top: 1rem;
  gap: 1.5rem;
  background: var(--color-light);
  box-shadow: 4px 4px 20px var(--color-light-4);
  border-radius: 4px;
  padding: 1% 1.5%;
  max-width: 1116px;
  min-width: 446px;
  height: 10%;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellFilling {
  color:var(--color-primary);
  background-color: var(--color-light-4);
  border-radius: 10px;
  border: 1px solid var(--color-light-2);
  min-width: 200px;
  margin: 4px 9px;
}

.dontOverflowColumn {
  overflow: visible !important;
  white-space: pre-wrap !important;
}

.icons {
  font-size: 1.4rem;
  align-self: center;
  cursor: pointer;
}

.hintIcon {
  margin-right: 5px;
  font-size: 0.7em;
}

.history-title {
  font-weight: var(--main-font-weight);
  margin: 1.7rem 0;
}

.empty {
  margin: 0 auto;
}

.link
.link:hover {
  color:var(--color-input-text);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 0px 40px;
}

.wrapperButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.text {
  font-size: 25px;
  font-weight: 500;
  color: var(--color-dark);
  display: flex;
  justify-content: end;
}

.textCell {
  margin-right: 30px;
}

.tooltip :global(.ant-tooltip-inner) {
  color: var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  max-width: 400px;
  width: max-content;
}

.tooltip :global(.ant-tooltip-arrow) {
  display: none;
}

.tooltipArrayContainer.tooltip :global(.ant-tooltip-inner) {
  max-width: initial;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 1.1rem;
  margin-right: 10px;
}

.button {
  color: var(--color-dark);
  border: 1px solid var(--color-input-text);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  height: 60px;
  padding: 15px 14px 15px 14px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-right: 20px;
}

.button:focus {
  border-color: var(--color-input-text);
}

.button:hover {
  border: 1px solid var(--color-input-text);
  color: var(--color-dark);
}

.button:global(.ant-btn) {
  padding-top: 15px !important;
}

.button:last-child {
  margin-right: 0;
}

.iconButton {
  font-size: 2rem;
  align-self: center;
}

.button:global(.ant-btn > .anticon) {
  line-height: 0;
}

.knowledgeBase {
  padding-right: 8px;
  background-color: var(--color-bright-5);
  width: 5px;
  height: 30px;
  margin-right: 5px;
  margin-left: -10px;
}

.wrapperBase {
  display: flex;
  align-items: center;
}

.rowBtn {
  min-width: 1.5rem;
  font-size: 1.2rem;
  fill: var(--color-additional-elements);
  user-select: none;
  cursor: pointer;
  line-height: 1.5rem;
  text-align: right;
  background: inherit;
}

.isDisabled .rowBtn {
  cursor: initial;
  pointer-events: none;
}

.wrapperForCardTable {
  padding: 0;
}

.filtersTools_hidden {
  margin-top: -30px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .button {
    height: 45px;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .knowledgeBase {
    height: 20px;
  }

  .text {
    font-size: 20px;
  }

  .wrapper {
    padding: 0px 40px 0px 40px;
  }

  .wrapTable {
    padding: 0px 40px 0px 40px;
  }

  .hintIcon {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .button {
    font-size: 16px;
    height: 35px;
    margin-bottom: 15px;
    /*border-radius: 5px;*/
  }

  .iconButton {
    font-size: 1.6rem;
    align-self: center;
  }

  .text {
    font-size: 15px;
  }

  .wrapper {
    padding: 0px 30px 0px 30px;
  }

  .icon {
    font-size: 0.9rem;
    margin-right: 10px;
  }

  .wrapTable {
    padding: 0px 30px 0px 30px;
  }

  .button:global(.ant-btn) {
    padding-top: 15px !important;
  }

  .tooltipArray div {
    font-size: 13px;
    line-height: 12px;
  }

  .icon {
    font-size: 1rem;
  }

  .hintIcon {
    font-size:  0.7em;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .filtersTools_hidden {
    margin-top: -20px;
  }

  .button {
    font-size: 10px;
    height: 32px;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
    padding: 8px 9px 8px 9px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .knowledgeBase {
    margin-left: -8px;
  }

  .button:global(.ant-btn) {
    padding-top: 8px !important;
  }

  .iconButton {
    font-size: 1rem;
    margin-top: 2px;
  }

  .wrapperBase {
    height: 20px;
  }

  .text {
    font-weight: 500;
    /* margin-right: 35px; */
    align-self: end;
    font-size: 10px;
  }

  .icon {
    font-size: 0.8rem;
  }

  .wrapTable {
    height: calc(100vh - 45%);
    padding: 0px 25px 0px 25px;
  }

  .wrapper {
    padding: 0px 25px 0px 25px;
  }

  .tooltipArray div {
    font-size: 8px;
    padding: 0;
    line-height: 10px;
  }

  .cellFilling {
    font-size: 6px;
    min-width: 100px;
  }

  .table .row .rowBtn {
    min-width: 1.3rem;
  }

  .icon {
    font-size: 0.6rem;
  }

  .hintIcon {
    font-size: 0.7em;
  }
}