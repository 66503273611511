.container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: flex-end;
}

.requestChart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-light-2);
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.tooltip {
  /* background-color: var(--color-bright-9); */
}

.test {
  background-color: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  box-shadow: 4px 4px 20px var(--color-light-4);
  border-radius: 10px;
  padding: 20px;
}

.wrapperTooltip {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 710px;
  height: 460px;
  justify-content: end;
  align-items: center;
  font-family: var(--main-font-family);
}

.wrapperTooltipRA {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  font-family: var(--main-font-family);
}

.textTooltip {
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  color: var(--color-dark);
  text-align: left;
}

.elementTableWrap {
  padding-right: 10px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
  width: 330px;
}

.elementTableWrapRA {
  padding-right: 10px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
  width: 100%;
}

.elementTableContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elementTable {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: var(--color-dark);
  text-align: left;
}

.subtitleTooltip {
  padding-right: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color:var(--color-dark);
  text-align: left;
  padding-top: 15px;
  width: 330px;
  display: flex;
  justify-content: space-between;
}

.subtitleTooltipRA {
  padding-right: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color:var(--color-dark);
  text-align: left;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.wrapCount {
  font-weight: var(--main-font-weight);
  font-size: 18px;
  line-height: 18px;
  color: var(--color-additional-elements);
}

.wrapTime {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  align-items: center;
  min-height: 110px;
  font-family: var(--main-font-family);
  width: 710px;
}

.statusWrap {
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  width: 88%;
  justify-content: space-between;
}

.statusTextWrap {
  padding-right: 10px;
  display: flex;
  justify-content: right;
  width: 330px;
}

.colorWrap {
  width: 20px;
  height: 20px;
  /* background-color: var(--color-bright-9); */
  display: flex;
  flex-wrap: wrap;
}

.containerLineTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 100%;
}

.containerLineThree {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chart {
  align-self: center;
  padding-left: 70px;
}

.wrapChart {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-light);
}

.text {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 18px;
  color: var(--color-dark);
  margin-right: 15px;
}

.text:last-child {
  margin-right: 0px;
}

.color {
  width: 30px;
  height: 30px;
  mix-blend-mode: normal;
  border-radius: 4px;
  margin-right: 15px;
}

.icon {
  font-size: 1.7rem;
  margin-right: 15px;
}

.titleLeft {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 18px;
  color:var(--color-dark);
  transform: rotate(270deg);
  margin-top: 250px;
  align-self: center;
  white-space: nowrap;
  width: 2%;
  margin-right: -70px;
}

.titleRight {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 18px;
  color:var(--color-dark);
  transform: rotate(270deg);
  margin-bottom: -200px;
  align-self: center;
  white-space: nowrap;
  width: 5%;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}


@media screen and (max-width: 2559px) and (min-width: 1950px) {

  .titleLeft {
    font-size: 20px;
  }

  .titleRight {
    font-size: 20px;
  }

  .text {
    font-size: 20px;
  }

  .icon {
    font-size: 1.3rem;
  }

  .color {
    width: 20px;
    height: 20px;
  }

  .wrapTime {
    min-height: 110px;
    width: 580px;
  }

  .container {
    margin-bottom: 15px;
  }

  .containerLineTwo {
    margin-bottom: 15px;
  }

  .subtitleTooltip {
    width: 260px;
  }

  .elementTableWrap {
    width: 260px;
  }

  .statusTextWrap {
    width: 260px;
  }

  .wrapperTooltip {
    min-width: 580px;
    height: 450px;
  }

  requestChart {
    padding: 30px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {

  .chart {
    padding-left: 50px;
  }

  .titleLeft {
    font-size: 15px;
    margin-right: -50px;
  }

  .titleRight {
    font-size: 15px;
  }

  .text {
    font-size: 15px;
    margin-right: 10px;
  }

  .icon {
    font-size: 1rem;
    margin-right: 10px;
  }

  .color {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  .container {
    margin-bottom: 10px;
  }

  .containerLineTwo {
    margin-bottom: 10px;
  }

  .legend {
    margin-top: 20px;
  }

  .subtitleTooltip {
    font-size: 13px;
    padding-top: 5px;
    width: 290px;
  }

  .elementTable {
    font-size: 13px;
  }

  .elementTableWrap {
    padding-top: 10px;
    width: 290px;
  }

  .statusTextWrap {
    width: 290px;
  }

  .wrapTime {
    min-height: 60px;
    width: 595px;
  }

  .wrapperTooltip {
    min-width: 595px;
    height: 390px;
  }

  .statusWrap {
    width: 94%;
  }

  .textTooltip {
    font-size: 18px;
  }

  .wrapCount {
    font-size: 15px;
  }

  .test {
    padding: 10px 15px;
  }

  requestChart {
    padding: 20px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .chart {
    padding-left: 40px;
  }

  .test {
    padding: 5px;
  }

  .titleLeft {
    font-size: 10px;
    margin-top: 75px;
    margin-right: -40px;
  }

  .titleRight {
    font-size: 10px;
    margin-bottom: -50px;
  }

  .text {
    font-size: 10px;
    line-height: 12px;
    margin-right: 5px;
  }

  .icon {
    font-size: 0.5rem;
    margin-right: 5px;
  }

  .color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .container {
    margin-bottom: 5px;
  }

  .containerLineTwo {
    margin-bottom: 5px;
  }

  .legend {
    margin-top: 15px;
  }

  .subtitleTooltip {
    font-size: 10px;
    padding-top: 0;
    width: 200px;
  }

  .elementTable {
    font-size: 10px;
  }

  .elementTableWrap {
    padding-top: 5px;
    width: 200px;
  }

  .statusTextWrap {
    width: 200px;
  }

  .wrapperTooltip {
    min-width: 420px;
    height: 280px;
  }

  .statusWrap {
    width: 94%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .textTooltip {
    font-size: 13px;
  }

  .wrapCount {
    font-size: 10px;
  }

  .test {
    padding: 10px 15px;
  }

  .wrapTime {
    min-height: 65px;
    width: 420px;
  }

  .requestChart {
    padding: 20px;
  }

}