.block {
  font-size: 16px;
}

.name {
  font-weight: 700;
}

.text {
  font-weight: 400;
}

.splitter {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: var(--color-light-4);
}

.icon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
}

.row {
  display: flex;
  align-items: center;
}

.link {
  width: 100%;
  color: var(--color-input-text);
  text-overflow: ellipsis;
  overflow: hidden;
}

.link:hover {
  color: var(--color-input-text)
}

.link:active {
  color: var(--color-input-text)
}

