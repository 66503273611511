.container {
  display: flex;
  height: 100%;
  position: relative;
  padding-bottom: 15px;
  border-top: 1px solid var(--color-light-4);
}

.content {
  width: 75%;
}

.sider {
  /* height: 90vh; */
  background:var(--color-light);
  box-shadow: 4px 4px 20px var(--color-light-4);
  position: relative;
  gap: 6px;
  z-index: 2;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.buttonOpen {
  background-color:var(--color-primary);
  border-radius: 10px 10px 0 0;
  text-align: center;
  cursor: pointer;
  border: none;
  min-width: 150px;
  height: 35px;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 18px;
  margin-left: -270px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:var(--color-button-text);
}

.buttonOpen:focus {
  background-color:var(--color-primary);
  color: var(--color-button-text);
}

.buttonOpen:hover {
  background-color:var(--color-primary);
  color: var(--color-button-text);
}

.wrapper {
  display: flex;
  position: absolute;
  top: 0;
  right: -5px;
  z-index: 2;
  height: 100%;
}

.buttonWrapper {
  width: 1px;
  height: 70px;
  transform: rotate(270deg);
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .buttonOpen {
    min-width: 130px;
    height: 30px;
    margin-top: 3px;
  }
  .buttonWrapper {
    height: 70px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .buttonOpen {
    min-width: 100px;
    height: 20px;
    font-size: 16px;
    margin-top: 6px;
    margin-left: -200px;
  }

  .buttonWrapper {
    height: 55px;
  }
  .container {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 1200px) {
  .buttonOpen {
    min-width: 80px;
    height: 17px;
    border-radius: 5px 5px 0 0;
    font-size: 10px;
    margin-top: 0px;
    padding: 0;
    margin-left: -150px;
  }

  .buttonWrapper {
    height: 40px;
  }

  .container {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1199px) and (min-width: 200px) {
  .buttonOpen {
    min-width: 54px;
    height: 14px;
    font-size: 6px;
    margin-top: 4px;
    padding: 0;
    margin-left: -150px;
  }

  .buttonWrapper {
    height: 40px;
  }

  .container {
    padding-bottom: 0;
  }

}
