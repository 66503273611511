.loadingOverlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-dark);
    opacity: 0.8;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.spinIcon {
    color: var(--color-light-4);
    position: absolute;
    left: 50%;
    top: calc(50% - 100px);
    transform: translate(-50%, -50%)
}

.spinText {
    font-size: 0.8em;
    white-space: nowrap;
    text-align: center;
    width: 200px;
    left: -100px;
    position: relative;
}

.loading {
    display: flex;

}