body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /*основные цвета*/
  --color-primary: #4067BF;
  --color-secondary: #407BFF;
  --color-secondary-2: #4067BF17;
  /*светлые цвета*/
  --color-light: #FFFFFF;
  --color-light-2: #C6D1EC;
  --color-light-3: #749CB3;
  --color-light-4: #F4F4F4;
  --color-light-5: #F5F7FC;
  /*темные цвета*/
  --color-dark: #4F4F4F;
  --color-dark-2: #B0B7BD;
  /*цвета для графиков*/
  --color-bright: #EF3F5C;
  --color-bright-2: #B9245B;
  --color-bright-3: #FF9950;
  --color-bright-4: #FFE880;
  --color-bright-5:#5BE3B1;
  --color-bright-6:#9E2C3F;
  --color-bright-7:#4BBD93;
  --color-bright-8:#FF8B00;
  --color-bright-9: #ff0000;
  --color-bright-10: #8DC9FF;
  /*цвет фона страниц*/
  --color-background: #FFFFFF;
  /*цвет дополнительных элементов*/
  --color-additional-elements: #4067BF;
  --color-input-text: #4067BF;
  --color-title: #4067BF;
  --color-button-text: #FFFFFF;

  --main-font-family: 'Lato', sans-serif;
  --main-font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
