.container {
  padding: 10px 20px 0 20px;
  flex: 1 1 auto;
  overflow-y: auto;
}

.container :global(.ant-menu) {
  border-right: 0;
  font-size: 16px;
}

.container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span):not(:global(.anticon)) {
  font-weight: var(--main-font-weight);
  font-size: 18px;
  line-height: 20px;
  flex: 1 1 auto;
  white-space: break-spaces;
}

.container :global(.ant-menu-inline .ant-menu-item:not(:last-child)) {
  margin-bottom: 10px !important;
  margin-top: 0;
}

.container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item){
  line-height: 20px;
}

.container :global(.ant-menu-item:active){
  background-color: transparent;
}

.container :global(.ant-menu-sub.ant-menu-inline) {
  background: none;
}

.container :global(.ant-menu-submenu-arrow) {
  display: none;
}

.container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span.anticon) {
  font-size: 20px;
  fill: var(--color-dark-2);
}

.container :global(.ant-menu-inline .ant-menu-submenu-title) {
  padding-right: 12px;
}

.container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-title) {
  padding-left: 12px !important;
  background: var(--color-light-4);
  border-radius: 3px;
}

.container :global(.ant-menu-inline .ant-menu-item::after) {
  border: none;
}

.container :global(.ant-menu-inline .ant-menu-item){
  height: auto;
  padding: 0;
}

.container :global(.ant-menu-submenu-title .anticon + span) {
  margin-left: 8px;
}

.container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
  height: 60px;
  font-size: 20px;
  line-height: 15px;
  margin-bottom: 20px;
}

.container :global(.ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title span),
.container :global(.ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title span a) {
  color: var(--color-button-text);
  fill: var(--color-button-text);
}

.container :global(.ant-menu .ant-menu-submenu .ant-menu-title-content) {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-open .ant-menu-submenu-title) {
  background-color:var(--color-primary);
  color: var(--color-light);
  margin-bottom: 20px;
}

.container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
  padding-left: 40px !important;
  width: 100%;
  white-space: pre-line;
  border: none;
}

.container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item):last-child {
  margin-bottom: 20px;
}

.container :global(.ant-menu .ant-menu-submenu .ant-menu-item.ant-menu-item-open) {
  background: none;
}

.container :global(.ant-menu-inline .ant-menu-submenu-title) {
  margin-top: 0;
  margin-bottom: 0;
}

.container :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background-color: transparent;
}

.container :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) > span > a {
  color: var(--color-additional-elements);
}

.container :global(.ant-menu-item-selected a) {
  color: var(--color-additional-elements);
}

/* .container :global(.ant-menu-item .ant-menu-item-active .ant-menu-item-only-child):hover {
  color: var(--color-bright-9);
} */

.container :global(.ant-menu-item a) {
  color: var(--color-dark);
}


.submenu {
  z-index: 10;
}

.container :global(.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after) {
  border: none !important;
}

.titleLink {
  z-index: 11;
  color: var(--color-dark);
}

.titleLink:hover {
  color: var(--color-dark);
}

.container :global(.ant-menu) .submenu {
  border-radius: 3px;
}

@media (max-width: 1300px) {
  /*.container {*/
  /*  padding: 20px 12px;*/
  /*}*/

  .container :global(.ant-menu) {
    font-size: 14px;
  }

  .container :global(.ant-menu) .submenu {
    margin-bottom: 0px;
  }

  .container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
    height: 60px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span.anticon) {
    font-size: 30px;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-title) {
    padding-left: 10px !important;
  }

  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    padding-left: 10px !important;
  }

  .container :global(.ant-menu-inline .ant-menu-submenu-title) {
    padding-right: 10px;
  }

  .container :global(.ant-menu-submenu-title .anticon + span) {
    margin-left: 10px;
  }
}


@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    padding-left: 39px !important;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span):not(:global(.anticon)) {
    font-size: 16px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-title-content) {
    font-size: 16px;
  }

  .container {
    padding: 20px 20px 0 20px;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-open .ant-menu-submenu-title){
    height: 50px;
  }

  .container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
    height: 50px;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-open .ant-menu-submenu-title) {
    margin-bottom: 15px;
  }

  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item):last-child {
    margin-bottom: 15px;
  }

  .container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
    margin-bottom: 15px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    padding-left: 37px !important;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span):not(:global(.anticon)) {
    font-size: 14px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-title-content) {
    font-size: 14px;
    line-height: 20px;
  }

  .container :global(.ant-menu-inline .ant-menu-item:not(:last-child)) {
    margin-bottom: 10px !important;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-open .ant-menu-submenu-title) {
    margin-bottom: 12px;
    height: 40px;
  }

  .container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
    height: 40px;
    margin-bottom: 12px;
  }

  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item):last-child {
    margin-bottom: 12px;
  }

  .container {
    padding: 20px 20px 0 20px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span.anticon) {
    font-size: 17px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 1300px) {
  .container :global(.ant-menu .ant-menu-submenu .ant-menu-title-content) {
    font-size: 10px;
    line-height: 10px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    padding-left: 26px !important;
    width: 87%;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-title-content) {
    font-size: 8px;
    line-height: 10px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span):not(:global(.anticon)) {
    font-size: 8px;
    line-height: 8px;
  }

  .container :global(.ant-menu-inline .ant-menu-item:not(:last-child)) {
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .container :global(.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item) {
    position: relative;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-open .ant-menu-submenu-title) {
    margin-bottom: 10px;
    height: 25px;
  }

  .container :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title span.anticon) {
    font-size: 15px;
  }

  .container :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
    height: 25px;
    margin-bottom: 10px;
  }

  .container :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item):last-child {
    margin-bottom: 10px;
  }

  .container {
    padding: 5px 15px 0 15px;
  }

  .container :global(.ant-menu-submenu-title .anticon + span) {
    margin-left: 5px;
  }

  .container :global(.ant-menu-inline.ant-menu-root .ant-menu-submenu-title) {
    padding-left: 7px !important;
    padding-right: 7px;
  }

}
