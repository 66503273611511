.wrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.titleArea {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-top: 1px;
}

.buttonsArea {
  width: 100%;
}

.back {
  margin-right: 1rem;
  cursor: pointer;
}

.titleBox{
  display: flex;
}

.overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflowedBox {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  margin-bottom: 0;
  font-weight: var(--main-font-weight);
  color: var(--color-title);
  font-size: 45px;
  line-height: 38px;
  padding: 23px 0 25px 0;
}

.icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.separator {
  margin: 0px 10px;
  font-size: 45px;
  color: var(--color-additional-elements);
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .title {
    font-size: 30px;
    line-height: 28px;
    padding: 18px 0 20px 0;
  }

  .icon {
    font-size: 1.8em;
  }

  .separator{
    font-size: 30px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .title {
    font-size: 24px;
    padding: 10px 0 15px 0;
    line-height: 18px;
  }

  .icon {
    font-size: 1.6em;
    margin-bottom: 10px;
  }

  .back {
    margin-right: 0.7rem;
    cursor: pointer;
  }

  .separator{
    font-size: 24px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .title {
    font-size: 20px;
    padding: 10px 0 10px 0;
    line-height: 18px;
  }

  .icon {
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .back {
    margin-right: 0.5rem;
    cursor: pointer;
  }

  .separator{
    font-size: 20px;
  }
}
