.filtersList {
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  list-style: none;
  padding:0 15px;
  width: 100%;
}

.buttonFilterTooltip {
  border: 2px solid var(--color-input-text);
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  color:var(--color-input-text);
}

.elementList:global(.ant-list-item) {
  border: none;
  padding: 0;
}

:global(.rc-virtual-list-holder){
overflow-x: hidden;
}

.buttonFilterTooltip:hover, .buttonFilterTooltip:focus{
  border: 2px solid var(--color-input-text);
  color: var(--color-input-text);
}

.search {
   border: 1px solid var(--color-light-2);
   box-shadow: 5px 5px 10px var(--color-light-4);
   border-radius: 10px;
   margin-bottom: 15px;
   width: 100%;
 }

.search :global(.ant-input-affix-wrapper) {
  height: 100%;
  background: transparent;
  border-radius: 4px;
  padding: 10px 0 10px 31px;
  line-height: unset;
  border: none;
}

.listElement {
  display: block;
  font-weight: var(--main-font-weight);
  font-size: 18px;
  line-height: 22px;
  color: var(--color-dark);
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search input {
  line-height: 1;
  font-size: 1.5rem;
  background: transparent;
  font-weight: var(--main-font-weight);
  color: var(--color-input-text);
  display: flex;
}

.iconSearch {
  font-size: 2.3rem;
}

.search :global(.ant-input::placeholder) {
  color:var(--color-input-text);
}

.search :global(.ant-input-prefix) {
  margin-right: 22px;
}


.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

@media screen and (max-width: 2559px) and (min-width: 1949px) {
  .listElement {
    width: 310px;
    font-size: 16px;
  }

  .search input {
    font-size: 18px;
  }

  .filtersList {
    /*height: 160px;*/
    padding: 12px;
  }

}


@media screen and (max-width: 1950px) and (min-width: 1500px) {
  .listElement {
    width: 230px;
    font-size: 14px;
  }

  .search input {
    font-size: 16px;
  }

  .filtersList {
    /*height: 130px;*/
    padding: 10px;
  }

  .buttonFilterTooltip{
    font-size: 16px;
    padding: 10px 15px;
    border-width: 1px;
  }

  .iconSearch {
    font-size: 1.6rem;
  }

  .search :global(.ant-input-affix-wrapper) {
    height: 40px;
  }

  .buttonFilterTooltip:hover, .buttonFilterTooltip:focus{
    border-width: 1px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .listElement {
    width: 120px;
    font-size: 10px;
    line-height: 15px;
  }

  .search input {
    font-size: 12px;
  }

  .filtersList {
    /*height: 100px;*/
    padding: 5px;
    border-radius: 5px;
  }

  .buttonFilterTooltip{
    font-size: 10px;
    padding: 5px ;
    border-width: 1px;
    border-radius: 5px;
  }

  .buttonFilterTooltip:hover, .buttonFilterTooltip:focus{
    border-width: 1px;
  }

  .iconSearch {
    font-size: 1rem;
  }

  .search :global(.ant-input-affix-wrapper) {
    height: 30px;
    padding: 5px 0 5px 11px;
  }
  .search {
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .buttonsContainer{
    margin-top: 8px;
  }

}

