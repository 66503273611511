/**
* controls
**/

#irkut .ant-form-vertical .ant-form-item-label {
  padding: 0;
}

#irkut .ant-form-horizontal {
  padding: 0;
}

#irkut .ant-row-main-content {
  height: 100%;
}

#irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  align-self: flex-start;
  font-size: 1rem;
  margin-right: 1px;
  margin-top: 10px;
}

#irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: var(--color-bright-9);
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 10px;
}

#irkut .ant-form-item-has-error .ant-select-selector,
#irkut .ant-form-item-has-error .ant-input-affix-wrapper,
#irkut .ant-form-item-has-error .ant-input-status-error {
  border-color: var(--color-bright-9);
}


#irkut .ant-form-item-label > label {
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
  font-size: 22px;
  color: var(--color-dark);
  position: relative;
  padding-left: 0;
  max-width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#irkut .ant-col-24 {
  padding-left: 29.5px !important;
  padding-right: 29.5px !important;
}

#irkut .ant-col-18 {
  padding-left: 29.5px !important;
  padding-right: 29.5px !important;
}

#irkut .ant-col-16 {
  padding-left: 29.5px !important;
  padding-right: 29.5px !important;
  align-self: center;
}

#irkut .ant-form-item {
  margin-bottom: 12px;
}

#irkut .ant-col-12 {
  padding-left: 29.5px !important;
  padding-right: 29.5px !important;
}

#irkut .ant-col-8 {
  padding-left: 29.5px !important;
  padding-right: 29.5px !important;
}

#irkut .ant-form-vertical .ant-form-item-label > label {
  padding-bottom: 0px;
}

#irkut .ant-col-6 {
  padding-right: 29.5px !important;
  padding-left: 29.5px !important;
  padding-bottom: 0px !important;
  align-self: center;
}

#irkut .modal-onlytext .ant-modal-content, .modal-withform .ant-modal-content{
  text-align: left !important;
}

body,
#irkut .ant-input-affix-wrapper,
#irkut .ant-layout-header {
  line-height: 1;
}

/**
* webscroll
**/

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
}

html {
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
}

/**
* make main content scrollable
**/
.main-content__wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 0 0 0 !important;
  height: 100%;
  position: relative;
}

.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.highlight {
  display: inline-block;
  font-weight: 700;
  background: var(--color-bright-10);
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  #irkut .ant-col-6 {
    padding-left: 10px !important;
  }

  .main-content {
    padding: 0px 0 20px 0 !important;
  }

  #irkut .ant-col-8 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  #irkut .ant-col-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }

  .main-content {
    padding: 0 0 0px 0 !important;
  }

  #irkut .ant-form-item {
    margin-bottom: 0px;
  }

  #irkut .ant-col-8 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

/**
* Modals
**/

.ant-modal-wrap {
  background: var(--color-light-2);
  backdrop-filter: blur(4px);
}

.ant-modal.modal-withform {
  max-width: 83%;
}

.modal-onlytext .ant-modal-content,
.modal-withform .ant-modal-content {
  border-radius: 10px;
  text-align: center;
  font-family: var(--main-font-family);
}

.modal-onlytext, .ant-modal:not(.modal-withform){
  width: max-content !important;
  max-width: 700px !important;
}


.modal-onlytext .ant-modal-header,
.modal-withform .ant-modal-header {
  padding: 2rem 1.5rem 0 1.5rem;
  border-bottom: none;
  border-radius: inherit;
}

.modal-onlytext .ant-modal-close-x,
.modal-withform .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.modal-onlytext .ant-modal-confirm-body .ant-modal-confirm-title,
.modal-onlytext .ant-modal-title,
.modal-withform .ant-modal-title {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-title);
}

.modal-onlytext .ant-modal-confirm-body .ant-modal-confirm-content > span{
  color: var(--color-dark);
}

.modal-onlytext .ant-modal-body,
.modal-onlytext .ant-modal-body {
  padding: 0.8rem 5%;
  padding-top: 10px;
}

.modal-withform .ant-modal-confirm-body,
.modal-withform .ant-modal-body {
  padding: 1.5rem;
}

.modal-withform .ant-modal-body .ant-form {
  display: flex;
  flex-wrap: wrap;
}

.modal-withform .ant-modal-confirm-body .ant-form > div,
.modal-withform .ant-modal-body .ant-form > div {
  min-width: 200px;
}

.modal-onlytext .ant-modal-confirm-body span,
.modal-onlytext .ant-modal-body span,
.modal-withform .ant-modal-body span {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: var(--main-font-weight);
  line-height: 1.4rem;
  /* color: var(--color-primary); */
}

.modal-onlytext .ant-modal-body span {
  font-size: 1.2rem;
   color: var(--color-dark);
}

.modal-onlytext .ant-modal-confirm-btns,
.modal-onlytext .ant-modal-footer,
.modal-withform .ant-modal-footer {
  border-top: none;
  padding: 0 1.5rem 2rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.modal-onlytext.ant-modal-confirm-confirm .ant-modal-body .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  float: none;
}

.modal-onlytext.ant-modal-confirm-confirm .ant-modal-body {
  padding-bottom: 0px;
  /* padding: 1.5rem 15%; */
}

.modal-onlytext .ant-modal-confirm-btns .ant-btn,
.modal-onlytext .ant-modal-footer .ant-btn,
.modal-withform .ant-modal-footer .ant-btn {
  background-color:var(--color-primary);
  border-radius: 10px;
  height: 3rem;
  width: 10.5rem;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 1.2rem;
  color:var(--color-button-text);
  border: none;
}

.modal-onlytext .ant-modal-confirm-btns .ant-btn span {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--color-button-text);
}

.modal-onlytext .ant-modal-footer .ant-btn-primary,
.modal-withform .ant-modal-footer .ant-btn-primary {
  order: -1;
}

.ant-select-arrow .anticon > svg {
  vertical-align: middle !important;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  #irkut .ant-form-item-label > label {
    font-size: 20px;
  }

  #irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 20px;
    margin-top: 8px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  #irkut .ant-form-vertical .ant-form-item-label > label {
    padding-bottom: 0px;
  }

  #irkut .ant-form-item-label > label {
    font-size: 15px;
  }

  #irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 15px;
    margin-top: 6px;
  }

  #irkut .ant-col-24 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #irkut .ant-col-18 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #irkut .ant-col-16 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #irkut .ant-col-12 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .modal-onlytext .ant-modal-confirm-btns .ant-btn,
  .modal-onlytext .ant-modal-footer .ant-btn,
  .modal-withform .ant-modal-footer .ant-btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  #irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    padding-left: 0;
  }

  #irkut .ant-form-vertical .ant-form-item-label > label {
    padding-bottom: 0;
  }

  #irkut .ant-form-item-label > label {
    font-size: 10px;
  }


  #irkut .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 10px;
    margin-top: 0;
  }

  #irkut .ant-col-24 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  #irkut .ant-col-18 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  #irkut .ant-col-16 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  #irkut .ant-col-12 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .modal-onlytext .ant-modal-confirm-btns .ant-btn,
  .modal-onlytext .ant-modal-footer .ant-btn,
  .modal-withform .ant-modal-footer .ant-btn {
    min-width: 105px;
  }
}

th .ant-checkbox-wrapper  {
  display: none;
}

.ant-table-selection-column {
  background: inherit;
}

thead tr th:first-child .ant-checkbox-inner::after {
  background: var(--color-bright-9);
}

.ant-table-fixed-header {
  border-radius: 10px !important;
}
