.panelWrapper:not(:first-child) {
  padding-top: 16px;
  border-top: 1px solid var(--color-dark-2);
  margin-top: 16px;
}

.title {
  margin-bottom: 16px;
  padding-bottom: 10px;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--color-dark-2);
}

.titleLink {
  color: var(--color-primary);
}

.titleLink:hover {
  color: var(--color-primary);
}

.titleLink:active {
  color: var(--color-primary);
}

.icon {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.highlights {
  font-size: 16px;
}

.highlightTitle {
  color: var(--color-primary);
}

.highlightText {
  font-weight: 400;
  color: var(--color-dark);
}

.childrenWrapper {
  padding: 16px 0 0 20px;
  border-top: 1px solid var(--color-dark-2);
}

.collapseIcon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.collapseWrapper :global(.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header) {
  cursor: default;
}

.collapseWrapper :global(.ant-collapse) {
  border: 2px solid var(--color-dark-2);
  border-radius: 15px;
  position: relative;
}

.collapseWrapper :global(.ant-collapse > .ant-collapse-item) {
  border: none;
}

.collapseWrapper :global(.ant-collapse-item > .ant-collapse-content) {
  border: none;
}

.collapseWrapper :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  position: static;
}

.collapseWrapper :global(.ant-collapse-content > .ant-collapse-content-box),
.collapseWrapper :global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
  padding-top: 0;
}