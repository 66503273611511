.search {
  width: 70%;
  /* height: 100%; */
  display: flex;
  align-items: center;
}

.search :global(.ant-input-affix-wrapper) {
  background: var(--color-light-4);
  height: 60px;
  border-radius: 4px;
  padding-left: 20px;
  line-height: unset;
  border: none;
  width: 100%;
  max-width: 1356px;
}

.search :global(.ant-dropdown-trigger) {
  background: var(--color-light-4);
  height: 60px;
  width: 335px;
  min-width: 335px;
  border-right: 3px solid var(--color-dark-2);
  font-size: 25px;
  font-weight: 400;
  color: var(--color-dark);
  display: flex;
  align-items: center;
}

.search :global(.ant-space-align-center) {
  justify-content: space-between;
  padding: 0 20px;
}

.search :global(.ant-btn-primary) {
  background: var(--color-primary);
  width: 80px;
  height: 60px;
  border: none;
  font-size: 18px;
  font-weight: var(--main-font-weight);
  color: var(--color-button-text);
}

.search :global(.ant-input-group-addon) {
  left: 0 !important;
}

.search input {
  line-height: 1;
  background:var(--color-light-4);
  color:var(--color-dark-2);
  font-size: 20px;
  font-weight: 400;
}

.iconSearch {
  font-size: 2.3rem;
}

.search :global(.ant-input::placeholder) {
  color: var(--color-dark-2);
  opacity: 1;
  font-size: 25px;
  font-weight: 400;
}

.search :global(.ant-input-prefix) {
  margin-right: 22px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {

  .search :global(.ant-dropdown-trigger) {
    height: 50px;
    font-size: 20px;
    width: 305px;
    min-width: 305px;
  }

  .search :global(.ant-input-affix-wrapper) {
    height: 50px;
  }

  .search :global(.ant-btn-primary) {
    height: 50px;
    font-size: 16px;
  }

  .iconSearch {
    font-size: 2rem;
  }

  .search :global(.ant-input::placeholder) {
    font-size: 20px;
  }
}

@media (max-width: 1300px) {
  .search :global(.ant-input-affix-wrapper) {
    padding-left: 6px;
  }

  .search :global(.ant-input-prefix) {
    margin-right: 12px;
  }

  .search {
    height: 30px;
  }

  .search :global(.ant-input::placeholder) {
    font-size: 15px;
    line-height: 18px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .iconSearch {
    font-size: 1.6rem;
  }

  .search input {
    font-size: 20px;
  }

  .search :global(.ant-dropdown-trigger) {
    height: 40px;
    font-size: 15px;
    width: 245px;
    min-width: 245px;
  }

  .search :global(.ant-input-affix-wrapper) {
    height: 40px;
  }

  .search :global(.ant-btn-primary) {
    height: 40px;
    font-size: 14px;
  }

  .search :global(.ant-input::placeholder) {
    font-size: 15px;
  }

  .search :global(.ant-space-align-center) {
    padding: 0 8px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .iconSearch {
    font-size: 1rem;
  }

  .search input {
    font-size: 15px;
  }

  .search :global(.ant-dropdown-trigger) {
    height: 30px;
    font-size: 12px;
    border-right: 2px solid var(--color-dark-2);
    width: 155px;
    min-width: 155px;
  }

  .search :global(.ant-input-affix-wrapper) {
    height: 30px;
  }

  .search :global(.ant-btn-primary) {
    height: 30px;
    width: 60px;
    font-size: 10px;
  }

  .search :global(.ant-input::placeholder) {
    font-size: 12px;
  }

  .search :global(.ant-space-align-center) {
    padding: 0 6px;
  }
}