.supportInfo {
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 30px;
  width: 78%;
  height: 555px;
  background:var(--color-light);
  position: absolute;
  top: 200px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support {
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 30px;
  width: 100%;
  height: 580px;
  background: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supportTitle {
  padding-top: 53px;
  padding-bottom: 71px;
  font-weight: var(--main-font-weight);
  font-size: 40px;
  line-height: 44px;
  color: var(--color-primary);
}

.supportSubtitle {
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: var(--color-dark);
  padding-bottom: 71px;
}

.supportContactsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.supportContact {
  width: 45%;
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

.supportIcon {
  font-size: 4rem;
  margin-right: 40px;
}

.supportContactInfo {
  width: 100%;
  color: var(--color-primary);
}

.supportImportant {
  border-bottom: 1px solid var(--color-primary);
  width: max-content;
}

.link {
  color:var(--color-primary);
  width: max-content;
  border-bottom: 2px solid var(--color-primary);
}

.link:hover {
  color: var(--color-primary);
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {

  .supportTitle {
    font-size: 35px;
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .supportSubtitle {
    font-size: 25px;
    padding-bottom: 51px;
  }

  .supportContact {
    font-size: 25px;
    padding-bottom: 60px;
  }

  .supportInfo {
    height: 510px;
    top: 160px;
  }

  .supportIcon {
    font-size: 3.3rem;
    margin-right: 30px;
  }

  .support {
    height: 470px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .supportTitle {
    font-size: 25px;
    line-height: 27px;
    padding-top: 28px;
    padding-bottom: 38px;
  }

  .supportSubtitle {
    font-size: 20px;
    line-height: 22px;
    padding-bottom: 40px;
  }

  .supportContact {
    padding-bottom: 40px;
    font-size: 15px;
    line-height: 17px;
  }

  .supportInfo {
    height: 340px;
    width: 70%;
    top: 130px;
  }

  .supportContactsWrap {
    padding-bottom: 45px;
  }

  .support {
    height: 394px;
  }

  .supportIcon {
    font-size: 2.5rem;
    margin-right: 25px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .supportContact {
    padding-bottom: 25px;
    font-size: 12px;
    line-height: 17px;
  }


  .supportTitle {
    font-size: 20px;
    line-height: 22px;
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .supportSubtitle {
    font-size: 15px;
    line-height: 17px;
    padding-bottom: 28px;
  }

  .supportIcon {
    font-size: 1.8rem;
    margin-right: 17px;
  }

  .supportInfo {
    top: 70px;
    box-shadow: 2px 2px 10px var(--color-light-4);
    border-radius: 15px;
    max-width: 70%;
    min-width: 670px;
    height: 250px;
  }

  .supportContactsWrap {
    padding-bottom: 50px;
  }

  .support {
    height: 250px;
  }
}