.pie {
  width: 61%;
  height: 61%;
}

.chart {
  align-self: center;
  height: 850px !important;
  width: 100% !important;
}

.title {
  /*align-self: end;*/
  white-space: break-spaces;
  width: 70%;
  height: 40px;
  margin-bottom: 30px;
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 30px;
  color:var(--color-dark);
}

.row {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.wrapColor {
  margin-top: 30px;
}

.wrapPie {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color:var(--color-dark);
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-bottom: 20px;
}

.wrapColorAndLabel {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

.header {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

.value {
  color: var(--color-additional-elements);
  font-weight: var(--main-font-weight);
  font-size: 18px;
  line-height: 18px;
}

.tooltip {
  padding-right: 20px;
  padding-left: 19px;
  padding-top: 15px;
}

div.reportTooltip :global(.ant-tooltip-inner) {
  max-width: max-content;
}

.tooltip:last-child {
  padding-top: 0px;
}

.test {
  background-color: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  box-shadow: 4px 4px 20px var(--color-light-4);
  border-radius: 10px;
  padding: 25px 45px;
}

.wrapperTooltip {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 398px;
  height: 550px;
  padding-right: 30px;
}

.textTooltip {
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  color: var(--color-dark);
  text-align: left;
}

.elementTableWrap {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: space-between;
  width: 380px;
  align-self: self-end;
}

.elementTableContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elementTable {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color:var(--color-dark);
  text-align: left;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.wrapCount {
  font-weight: var(--main-font-weight);
  font-size: 18px;
  line-height: 18px;
  color:var(--color-primary);
}


.chart {
  align-self: center;
  height: 850px !important;
}

.statusWrap {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.statusTextWrap {
  display: flex;
  justify-content: center;
  align-self: self-end;
  width: 80%;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .pie {
    width: 58%;
    height: 58%;
  }

  .title {
    width: 80%;
    font-size: 20px;
  }

  .chart {
    height: 700px !important;
  }

  .container {
    margin-bottom: 15px;
  }

  .elementTableWrap {
    width: 330px;
  }

  .wrapperTooltip {
    width: 350px;
    height: 530px;
    padding-right: 25px;
  }



}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .pie {
    width: 50%;
    height: 50%;
  }

  .chart {
    height: 600px !important;
    width: 100% !important;
  }

  .title {
    width: 75%;
    font-size: 15px;
  }

  .wrapper {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .row {
    width: 15px;
    height: 15px;
  }

  .wrapColor {
    margin-top: 20px;
  }

  .titleWrap {
    margin-bottom: 10px;
    width: 300px;
  }

  .value {
    font-size: 13px;
  }

  .tooltip {
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .wrapColorAndLabel {
    font-size: 15px;
  }

  .header {
    font-size: 15px;
  }

  .container {
    margin-bottom: 10px;
  }

  .elementTable {
    font-size: 15px;
  }

  .elementTableWrap {
    padding-top: 15px;
    width: 260px;
  }

  .wrapperTooltip {
    width: 300px;
    height: 480px;
    padding-right: 20px;
  }

  .textTooltip {
    font-size: 18px;
  }

  .wrapCount {
    font-size: 15px;
  }

  .test {
    padding: 10px 15px;
  }
  .statusTextWrap {
    width: 70%;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .pie {
    width: 45%;
    height: 45%;
  }

  .chart {
    height: 350px !important;
    width: 100% !important;
  }

  .title {
    width: 75%;
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 10px;
    height: 20px;
  }

  .wrapper {
    font-size: 8px;
    margin-bottom: 0px;
  }

  .row {
    width: 10px;
    height: 10px;
  }

  .wrapColor {
    margin-top: 10px;
  }

  .titleWrap {
    margin-bottom: 0px;
    width: 200px;
  }

  .value {
    font-size: 10px;
  }

  .tooltip {
    padding: 0;
  }

  .wrapColorAndLabel {
    font-size: 10px;
    line-height: 10px;
  }

  .header {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .container {
    margin-bottom: 5px;
  }

  .containerLineTwo {
    margin-bottom: 5px;
  }

  .legend {
    margin-top: 15px;
  }

  .subtitleTooltip {
    font-size: 10px;
    padding-top: 5px;
    width: 200px;
  }

  .elementTable {
    font-size: 10px;
  }

  .elementTableWrap {
    padding-top: 5px;
    width: 180px;
  }

  .wrapperTooltip {
    width: 200px;
    height: 260px;
    padding-right: 15px;
  }

  .textTooltip {
    font-size: 13px;
  }

  .wrapCount {
    font-size: 10px;
  }

  .test {
    padding: 10px 15px;
  }

  .statusWrap {
    margin-bottom: 0;
    margin-top: 0;
  }

}
