.wrap {
  height: 100vh;
  width: 100vw;
  background: #F6F8FB;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.formContainer{
  flex-direction: column;
  display: flex;
}

.modal :global(.ant-modal-footer){
  flex-wrap: wrap;
  padding-left: 200px;
  padding-right: 200px;
}

.modal :global(.ant-modal-body){
  padding-left: 200px;
  padding-right: 200px;
}

.subtitlePassword{
  text-align: center;
}

:global(.ant-modal-mask){
  background-color: transparent !important;
}

.blur {
  background: var(--color-light-2);
  backdrop-filter: blur(20px);
  z-index: 5000;
}

.form {
  width: 50%;
  max-width: 800px;
  min-width: 400px;
  height: 450px;
  box-shadow: 4px 4px 20px var(--color-light-4);
  border-radius: 10px;
  display: flex;
  background: var(--color-light);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: min(1%, 50px);
  position: relative;
}

.form-item.form-item_title {
  height: 33%;
  align-items: start;
  margin-left: 250px;
  justify-content: flex-end;
  margin-top: 10px;
}

::placeholder {
  color: var(--color-dark-10) !important;
  font-size: 18px;
}

.title_password {
  height: 15%;
  width: 500px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formHeader {
  background-color:var(--color-primary);
  height: 80px;
  width: 50%;
  max-width: 800px;
  min-width: 400px;
  color: var(--color-light);
  box-shadow: 4px 4px 20px var(--color-light-4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 10px 10px 15px 10px;
  font-size: 18px;
  line-height: 20px;
  position: relative;
  top: 15px
}

.formHeader_text {
  margin: 0;
  font-weight: normal;
}

.formHeader_span {
  font-weight: var(--main-font-weight);
}

.form-item a {
  color:var(--color-primary);
}

.form-item :global(.ant-form-item) {
  width: 67%;
  margin-bottom: 0px !important;
  height: 75px;
}


.form-item :global(.ant-form-item):last-child {
  margin-bottom: 0px !important;
}

.form-item .form-item_wide:global(.ant-form-item) {
  width: 100%;
  margin-bottom: 0 !important;
}

.form-item .form-item_wide :global(.ant-checkbox-group .ant-row):not(:last-child) {
  margin-bottom: 24px;
}

.form-item :global(.ant-form-item).form-button {
  width: min(25%, 376px);
}

.form-item.form-item_bottom :global(.ant-form-item).form-button {
  width: min(33%, 400px);
}

@media (max-width: 1300px) {
  .form-item.form-item_bottom :global(.ant-form-item).form-button {
    width: min(45%, 500px);
  }
}

.form-item_bottom {
  justify-content: flex-end;
  align-items: flex-end;
}

.form-item_bottom :global(.ant-row) {
  gap: 20px;
}

.form-item input {
  color: var(--color-primary);
}

.form-item :global(.ant-col .ant-form-item-control::placeholder) {
  color: var(--color-primary);
}

.form-item :global(.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input) {
  height: 100%;
  display: flex;
  border-radius: 10px;
}

.form-item :global(.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper){
  height: 50px;
  display: flex;
  border-radius: 10px;
}

.form-item:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-color:  var(--color-light-2);
}


.form-item :global(.ant-form-item-control-input-content){
  height: 100%;
}

.form-item :global(.ant-form-item):not(.no-wrap) :global(.ant-form-item-control-input) {
  background: var(--color-light);
  width: 100%;
  font-weight: 600;
  font-size: 25px;
  line-height: 18px;
  display: flex;
  align-items: center;
  height: 50px;

}

.form-item :global(.ant-form-item):not(:global(.ant-form-item-has-error)) :global(.ant-input-affix-wrapper),
.form-item :global(.ant-form-item):not(:global(.ant-form-item-has-error)) input {
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  border: 1px solid var(--color-light-2);
  height: 100%;
}

.form-item :global(.ant-input-affix-wrapper > input.ant-input){
  border: none;
  box-shadow: none;
}

.text {
  font-size: 14px;
  line-height: 15px;
  color: var(--color-dark-10);
  max-width: 400px;
  font-weight: 600;
}

.textForgot {
  font-size: 14px;
  line-height: 15px;
  color: var(--color-dark-10);
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 600;
}

.header {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: var(--color-dark-11);
  max-width: 500px;
}

.text:last-child {
  margin-top: 20px;
}

.textForm {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color:var(--color-dark-2);
  max-width: 422px;
}

.input {
  background: var(--color-light);
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 6px;
  max-width: 400px;
  align-self: center;
  margin-bottom: 10px;
}

.title {
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  user-select: none;
  margin-top: 20px;
   margin-bottom: 10px;
}

.form-item button[type="submit"] {
  background-color:var(--color-primary);
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  width: 180px;
  height: min(2.5rem, 70px);
  margin-top: 20px;
}

.form-item button[type="submit"]:disabled {
  background: var(--color-light-4);
  color: var(--color-dark-2);
}

.form-item button[type="submit"]:disabled:hover {
  background: var(--color-light-4);
}

.form-item button:not([type="submit"]) {
  background: var(--color-light);
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 10px;
  border: none;
  color: var(--color-dark-2);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 12px;
  line-height: 9px;
  text-align: center;
  width: 100%;
  height: min(2.5rem, 70px);
}

.forgot {
  font-family: var(--main-font-family);
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--main-font-weight);
  line-height: 1.5rem;
  letter-spacing: 0px;
  color:var(--color-primary);
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
}

.forgot:hover {
  color:var(--color-primary);
}

.form-item :global(.ant-checkbox-wrapper) {
  align-items: center;
}

.form-item :global(.ant-checkbox) {
  top: 0;
}

.close-btn {
  display: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: min(1%, 50px);
  right: min(1%, 50px);
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='25.6152' width='6.21448' height='35.9853' rx='3.10724' transform='rotate(45 25.6152 0)' fill='%23E5E5E5'/%3E%3Crect y='4.55469' width='6.21448' height='35.9853' rx='3.10724' transform='rotate(-45 0 4.55469)' fill='%23E5E5E5'/%3E%3C/svg%3E");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (max-width: 1300px) {
  .close-btn {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='25.6152' width='6.21448' height='35.9853' rx='3.10724' transform='rotate(45 25.6152 0)' fill='%23E5E5E5'/%3E%3Crect y='4.55469' width='6.21448' height='35.9853' rx='3.10724' transform='rotate(-45 0 4.55469)' fill='%23E5E5E5'/%3E%3C/svg%3E");
  }
}

.loader {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.loader :global(.ant-spin-container) {
  margin-top: 40vh;
}

.loader :global(.ant-spin-nested-loading) {
  height: auto;
}

.loader :global(.ant-spin-nested-loading > div > .ant-spin.ant-spin-spinning) {
  top: 30px;
}



@media screen and (max-width: 1949px) and (min-width: 1500px) {

  .form-item :global(.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper){
    height: 40px;
  }

  .text {
    font-size: 12px;
  }

  .textForgot {
    font-size: 12px;
    width: 450px;
  }

  .header {
    font-size: 15px;
  }

  .title {
    font-size: 15px;
  }

  .form-item :global(.ant-form-item):not(.no-wrap) :global(.ant-form-item-control-input) {
    height: 40px;
  }

  ::placeholder {
    font-size: 15px;
  }

  .form-item :global(.ant-form-item) {
    height: 65px;
  }

  .form-item button[type="submit"] {
    font-size: 16px;
  }

  .modal :global(.ant-modal-footer){
    padding-left: 150px;
    padding-right: 150px;
  }
  
  .modal :global(.ant-modal-body){
    padding-left: 150px;
    padding-right: 150px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .form {
    width: 450px;
    height: 260px;
  }

  .form-item :global(.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper){
    height: 30px;
  }

  .form-item :global(.ant-form-item) {
    height: 47px;
  }

  .form-item :global(.ant-form-item-explain){
    margin-left: 3px;
    font-size: 8px;
  }

  .form-item.form-item_title {
    margin-left: 145px;
    margin-top: 0;
    height: 30%;
  }

  .text {
    font-size: 10px;
  }

  .textForgot {
    font-size: 10px;
    width: 310px;
  }

  .header {
    font-size: 10px;
    max-width: 400px;
    line-height: 15px;
  }

  .title {
    font-size: 10px;
    margin-top: 0;
    line-height: 25px;
    margin-bottom: 0;
  }

  .form-item :global(.ant-form-item):not(.no-wrap) :global(.ant-form-item-control-input) {
    height: 25px;
    border-radius: 5px;
  }

  ::placeholder {
    font-size: 10px;
  }

  .formHeader {
    padding: 10px 10px 15px 10px;
    font-size: 10px;
    height: 70px;
    width: 450px;
  }

  .form-item button[type="submit"] {
    font-size: 10px;
    height:30px;
    width: 30%;
    margin-top: 0;
  }

  .title_password {
    height: 10%;
    width: 310px;
    margin-top: 20px;
  }

  .text:last-child {
    margin-top: 10px;
  }

  .form-item input {
   font-size: 10px;
  }

  .modal :global(.ant-modal-footer){
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .modal :global(.ant-modal-body){
    padding-left: 50px;
    padding-right: 50px;
  }

}