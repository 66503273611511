.container {
  display: flex;
}

.input {
  width: 100%;
}

.input:global(.ant-select .ant-select-selector) {
  background: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  border: 1px solid var(--color-light-2);
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  min-height: 60px;
}

.container :global(.ant-form-item) {
  width: 100%;
}

.input:global(.ant-select-multiple .ant-select-selection-item) {
  height: 100%;
  padding: 5px;
}

.input:global(.ant-select-multiple.ant-select-show-arrow .ant-select-selector) {
  padding-right: 45px;
}

.input:global(
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector
  ) {
  border: 1px solid var(--color-light-2);
  box-shadow: none;
  background-color: transparent;
}

.input:global(.ant-select-single .ant-select-selector) {
  min-height: 60px;
}

.input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
  color: var(--color-input-text);
  font-weight: var(--main-font-weight);
}

.input:global(
    .ant-select-single.ant-select-show-arrow.ant-select-disabled .ant-select-selection-item
  ) {
  color:var(--color-dark);
}

.input :global(.ant-select-selection-search .ant-select-selection-search-input) {
  color: var(--color-input-text);
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
}

.input :global(.ant-select-arrow) {
  font-size: 1.6rem;
  right: -11px;
  width: 1.6em;
  height: 1em;
  margin-top: -0.6em;
  margin-right: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.input:global(
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input
  ) {
  height: 100%;
}

.input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
  padding-right: 45px;
}

.dropdown {
  font-size: 30px;
  line-height: 18px;
  font-weight: var(--main-font-weight);
}

.dropdown:global(.ant-select-dropdown) {
  color: var(--color-dark-2);
  background: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
}

.dropdown :global(.ant-select-item) {
  height: 45px;
  color:var(--color-input-text);
  background:var(--color-light);
  font-size: 22px;
  line-height: 18px;
}

.dropdown :global(.ant-select-item-option-content) {
  height: 30px;
  display: flex;
  align-items: center;
}

.dropdown :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover) {
  background:var(--color-light-5);
}

.dropdown :global(.ant-select-item-option-selected) {
  color: var(--color-dark-2);
}

.icon {
  font-size: 1em;
  margin-left: -10px;
  margin-top: 5px;
  margin-right: 0.5em;
}

.iconContainer {
  font-size: 1em;
  margin-left: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-left: 1px solid var(--color-light-2);
}

.container :global(.ant-input-affix-wrapper-disabled) :global(.ant-input-suffix) .iconContainer {
  font-size: 1.5rem;
}

.container
  :global(.ant-input-affix-wrapper-disabled)
  :global(.ant-input-suffix)
  .iconContainer:hover {
  cursor: default;
}

.iconForTooltip {
  font-size: 1.1em;
  margin-left: 10px;
}

.iconForTransition {
  font-size: 1.1em;
  margin-left: 10px;
  margin-right: 10px;
  color:var(--color-primary);
}

.iconDisabled {
  color: var(--color-dark-2) !important;
}

.iconDisabled:hover {
  cursor: not-allowed;
}

.info {
  color:var(--color-primary);
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.tooltip :global(.ant-tooltip-inner) {
  color: var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  width: max-content;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .input:global(.ant-select .ant-select-selector) {
    min-height: 45px;
    font-size: 20px;
  }

  .input:global(.ant-select-multiple .ant-select-selection-item) {
    padding: 4px;
  }

  .input:global(.ant-select-multiple.ant-select-show-arrow .ant-select-selector) {
    padding-right: 40px;
  }

  .icon {
    font-size: 2em;
    margin-left: -5px;
    margin-top: -0.7px;
  }

  .iconContainer {
    font-size: 2.5em;
    margin-left: 0.5em;
    margin-right: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    border-left: 1px solid var(--color-light-2);
  }

  .container :global(.ant-input-affix-wrapper-disabled) :global(.ant-input-suffix) .iconContainer {
    margin-right: -15px;
    padding-right: 20px;
  }

  .iconForTooltip {
    font-size: 0.9em;
    margin-left: 10px;
  }

  .iconForTransition {
    font-size: 0.9em;
    margin-left: 10px;
    margin-right: 5px;
    color:var(--color-primary);
  }

  .input :global(.ant-select-arrow) {
    font-size: 0.6rem;
    right: -5px;
    width: 1.6em;
    height: 1em;
    margin-top: -0.5em;
    margin-right: 5px;
  }

  .dropdown :global(.ant-select-item) {
    height: 40px;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 20px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 20px;
  }

  .input:global(.ant-select-disabled .ant-select-arrow) {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .input:global(.ant-select .ant-select-selector) {
    min-height: 35px;
    font-size: 15px;
  }

  .input:global(.ant-select-multiple .ant-select-selection-item) {
    padding: 0 2px;
  }

  .input:global(.ant-select-multiple.ant-select-show-arrow .ant-select-selector) {
    padding-right: 35px;
  }

  .tooltip :global(.ant-tooltip-inner) {
    font-size: 15px;
  }

  .input:global(.ant-select) {
    align-self: center;
  }

  .icon {
    font-size: 1em;
    margin-left: 0px;
    margin-bottom: 2px;
  }

  .iconContainer {
    font-size: 1.1em;
    margin-left: 0.5em;
    margin-top: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.25em;
    border-left: 1px solid var(--color-light-2);
  }

  .container :global(.ant-input-affix-wrapper-disabled) :global(.ant-input-suffix) .iconContainer {
    font-size: 1.2rem;
    margin-right: -15px;
    margin-top: 0em;
    padding-right: 14px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }

  .iconForTooltip {
    font-size: 0.8em;
    margin-left: 10px;
  }

  .iconForTransition {
    font-size: 0.9em;
    margin-left: 8px;
    margin-right: 4px;
    color: var(--color-primary);
  }

  .input :global(.ant-select-arrow) {
    font-size: 1.1rem;
    right: 3px;
    width: 1.6em;
    height: 1em;
    margin-top: -0.6em;
    margin-right: 8px;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 20px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 15px;
    height: 30px;
    min-height: 25px;
  }

  .dropdown :global(.ant-select-item-option-content) {
    height: 20px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .input:global(.ant-select .ant-select-selector) {
    min-height: 30px;
    font-size: 10px;
    border-radius: 5px;
  }

  .input:global(.ant-select-multiple.ant-select-show-arrow .ant-select-selector) {
    padding: 0;
    padding-right: 30px;
  }

  .tooltip :global(.ant-tooltip-inner) {
    font-size: 8px;
    line-height: 13px;
  }

  .icon {
    font-size: 0.9em;
    margin-top: -0.1px;
    margin-left: 0;
  }

  .iconContainer {
    font-size: 1.3em;
    margin-left: 0.5em;
    margin-bottom: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    border-left: 1px solid var(--color-light-2);
  }

  .container :global(.ant-input-affix-wrapper-disabled) :global(.ant-input-suffix) .iconContainer {
    font-size: 1.3em;
    margin-right: -12px;
    margin-top: 0em;
    margin-bottom: 0em;
    padding-right: 12px;
    padding-top: 0.3em;
    padding-bottom: 0.2em;
  }

  .input:global(.ant-select) {
    align-self: center;
  }

  .iconForTooltip {
    font-size: 0.6em;
    margin-left: 10px;
    margin-bottom: 0px;
  }

  .iconForTransition {
    font-size: 0.8em;
    margin-left: 6px;
    margin-right: 1px;
    color: var(--color-primary);
  }

  .input :global(.ant-select-arrow) {
    font-size: 0.8rem;
    right: -5px;
    width: 1.6em;
    height: 1em;
    margin-top: -0.4em;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 15px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 10px;
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
    min-height: 20px !important;
    height: 25px;
  }

  .dropdown :global(.ant-select-item-option-content) {
    height: 15px;
  }

  .input:global(.ant-select-multiple .ant-select-selection-item) {
    padding: 0 2px;
  }

  .input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
    padding-right: 50px;
  }
}
