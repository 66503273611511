h2 {
  color:var(--color-primary);
  font-weight: var(--main-font-weight);
}

.list {
  column-gap: min(8%, 180px);
  row-gap: min(calc(100vh / 15), 115px) !important;
  display: block;
  padding: 0px 40px 0px 40px !important;
}

.collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
  border-bottom: 1px solid var(--color-light-2);
  padding: 8px;
  outline: none;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 18px;
}

.collapse:global(.ant-collapse) {
  background-color: transparent;
  border: none;
  width: 100%;
}

.collapse:global(.ant-collapse  .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
  width: 20px;
  height: 20px;
  color: var(--color-dark-2);
}

.wrapperTitle {
  padding: 10px 40px 0px 40px;
}

.title {
  margin-top: 23px;
  margin-bottom: 33px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 45px;
  line-height: 18px;
  color: var(--color-primary);
}

.button {
  background: var(--color-light);
  border: 1px solid var(--color-primary);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  padding: 15px 10px 15px 10px;
  height: 60px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:first-child {
  margin-right: 25px;
}

.wrapper {
  display: flex;
}

.icon {
  font-size: 2rem;
  align-self: center;
  margin-right: 20px;
}

.button:global(.ant-btn > .anticon) {
  line-height: 0;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 2559px) and (min-width: 1801px) {
  .button {
    height: 50px;
  }

  .wrapperTitle {
    padding: 10px 40px 0px 40px;
  }

  .title {
    font-size: 30px;
  }

  .list {
    padding: 0px 40px 0px 40px !important;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1500px) {
  .button {
    font-size: 16px;
    height: 40px;
  }
}


@media screen and (max-width: 2350px) and (min-width: 1500px) {
  .button {
    font-size: 16px;
  }

  .icon {
    font-size: 1.5rem;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .wrapperTitle {
    padding: 10px 30px 0px 30px;
  }

  .title {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .list {
    padding: 0px 30px 0px 30px !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .wrapperTitle {
    padding: 10px 25px 0px 25px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .list {
    padding: 0px 25px 0px 25px !important;
  }

  .button {
    font-size: 10px;
    height: 25px;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
    border-radius: 5px;
  }

  .icon {
    font-size: 1rem;
    margin-right: 10px;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    padding: 0 8px 8px 8px;
  }

  .collapse:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-left: 0 !important;
  }
}