.table {
  box-shadow: 5px 5px 10px var(--color-light-4);
  border: 1px solid var(--color-light-2);
  border-radius: 10px;
}

.table :global(.ant-table-cell a) {
  color: inherit;
}

.table :global(.ant-table-column-sorter-down) {
  font-size: 20px;
}

.table :global(.ant-table-column-sorter-up) {
  font-size: 20px;
}

.table :global(.ant-table-column-sorter-down.active),
.table :global(.ant-table-column-sorter-up.active) {
  color: var(--color-primary)
}

.table :global(td.ant-table-column-sort) {
  background-color: inherit;
}

.table :global(.ant-table-thead > tr > th) {
  background: unset;
  border: none;
  border-bottom: 1px solid var(--color-light-2);
  padding: 15px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-dark);
  background-color: var(--color-light);
}

:global(.ant-table-cell-fix-left) {
  background-color: inherit;
}

.modal :global(.ant-modal-body) {
  max-height: 70vh;
  overflow: scroll;
}

.formContainer {
  display: block !important;
}

:global(.ant-table-filter-dropdown) {
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  padding: 15px;
  height: auto;
  width: 480px;
}

:global(.ant-table-filter-dropdown .ant-dropdown-menu) {
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px var(--color-light-4);
  padding: 15px;
  border-radius: 10px;
}

.table :global(.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
  position: relative;
}

.table :global(.ant-table-selection-column) {
  text-overflow: clip !important;
}

.table :global(.ant-table-tbody > tr) {
  height: 36px;
  background-color: var(--color-light);
}

.table :global(.ant-table-tbody > tr > td) {
  padding: 10px 20px 10px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--color-input-text);
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.table :global(.ant-table-row td) {
  border-bottom: 1px solid var(--color-light-2);
}

.table :global(.ant-table-row):last-child {
  border-bottom: none;
}

.table :global(.ant-table-filter-trigger) {
  margin: -4px 60px -4px -4px;
}

.table :global(.ant-table-column-title) {
  flex: none;
}

.table :global(.ant-table-filter-column) {
  justify-content: flex-start;
}

.table :global(.ant-badge-status-dot) {
  height: 10px;
  width: 10px;
}

.table :global(.ant-table-filter-trigger:hover) {
  background-color: transparent;
}

.table :global(.ant-table-column-sorter) {
  color: var(--color-dark-2);
}

.table :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  cursor: pointer;
}

.table :global(.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after) {
  box-shadow: none;
}

.table :global(.ant-table-column-sorters::after) {
  position: relative;
}

.table :global(.ant-table-column-sorter) {
  margin-left: 6px;
}

.table :global(.ant-table-column-sorter-inner) {
  display: flex;
}

.table :global(.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):hover) {
  cursor: pointer;
  background-color: var(--color-light);
}

.table :global(.ant-table-thead > tr > th.ant-table-cell-fix-right).checkboxColumn {
  display: none;
}

.table :global(.ant-table-tbody > tr > td.ant-table-cell-fix-right).checkboxColumn {
  text-align: center !important;
  background: var(--color-light) !important;
  z-index: 9999 !important;
  fill: var(--color-primary);
}

.evenNumber {
  background-color: var(--color-light-5) !important;
}

:global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: inherit
}


.empty {
  background: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  width: 100%;
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
}

.emptyTitle {
  margin-top: 22px;
  color: var(--color-dark-2);
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 20px;
  max-width: 560px;
  text-align: center;
}

.row {
  margin-top: 24px;
  width: 98%;
  height: 68px;
  background: var(--color-light-26);
  border: 1px solid var(--color-light-25);
  box-sizing: border-box;
  border-radius: 15px;
}

.topRow {
  width: 12%;
  background: var(--color-light-26);
  border: 1px solid var(--color-light-25);
  box-sizing: border-box;
  border-radius: 15px;
}

.wrapper {
  display: flex;
  width: 98%;
  width: 150px;
  height: 22px;
  justify-content: space-between;
}

::-webkit-scrollbar:horizontal {
  width: 100px;
  height: 15px;
}

::-webkit-scrollbar:vertical {
  width: 15px;
  height: 100px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .table {
    padding-bottom: 30px;
  }

  .table :global(.ant-table-tbody > tr) {
    height: 40px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    font-size: 15px;
  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 17px;
    padding: 10px 10px;
  }

  :global(.ant-table-filter-dropdown) {
    width: 450px;
  }

  .row {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .row:last-child {
    margin-top: 0;
  }

  .table :global(.ant-table-column-sorter-down) {
    font-size: 18px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 18px;
  }

  .table :global(.ant-table-column-sorter) {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .table {
    padding-bottom: 10px;
    margin-top: 0px;
  }

  :global(.ant-table-filter-dropdown) {
    width: 350px;
  }

  .table :global(.ant-table-tbody > tr) {
    height: 36px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    padding: 4px 20px 6px 4px;
    font-size: 12px;
    width: 150px;
  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 14px;
    padding: 7px 7px;
  }

  .empty {
    height: 360px;
    margin-top: 0px;
  }

  .row {
    height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .row:last-child {
    margin-top: 0;
  }

  .emptyTitle {
    font-size: 16px;
  }

  .table :global(.ant-table-column-sorter-down) {
    font-size: 15px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 15px;
  }

  .table :global(.ant-table-column-sorter) {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .table {
    padding-bottom: 5px;
    border-radius: 5px;
    margin-top: 0;
  }

  .table :global(.ant-table-tbody > tr > td) {
    font-size: 6px;
    width: 100px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  .table :global(.ant-table-tbody > tr) {
    height: 26px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    padding: 2px;
  }

  :global(.ant-table-filter-dropdown) {
    width: 200px;
    padding: 8px;
  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 8px;
    padding: 0 2px;
  }

  .empty {
    height: 229px;
    margin-top: 0;
  }

  .row {
    height: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .row:last-child {
    margin-top: 0;
  }

  .emptyTitle {
    font-size: 15px;
  }

  .table :global(.ant-table-column-sorter-down) {
    font-size: 10px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 10px;
  }

  .table :global(.ant-table-filter-trigger) {
    margin: 0px 60px 0px 0px;
    padding: 0;
  }

  .table :global(.ant-table-column-sorter) {
    margin-left: 2px;
  }
}

.empty__small {
  height: auto;
}

.emptyTitleForCardTable {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinBox {
  margin-bottom: 12px;
}