.card {
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 15px;
  width: 100%;
}

.cardImage {
  object-fit: cover;
  height: 224px;
  cursor: pointer;
}

.card :global(.ant-card-cover img){
  border: 1px solid var(--color-primary);
  border-radius: 15px 15px 0 0;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.cardDate {
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  color: var(--color-dark-2);
}

.cardCategory {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  color: var(--color-dark);
  white-space: pre-line;
  width: 50%;
  text-align: right;
}

.cardTitleWrap {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 30px;
  color: var(--color-dark);
  border-bottom: 5px solid var(--color-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.cardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardIcon {
  font-size: 2.3rem;
}

.cardInfo {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dark-2);
  margin-bottom: 10px;
  margin-top: 15px;
  height: 100px;
}

.cardButton {
  float: right;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  font-weight: var(--main-font-weight);
  font-size: 26px;
  color: var(--color-dark);
  padding: 25px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cardButton:hover {
  border: 1px solid var(--color-primary);
  color: var(--color-dark);
}

.cardButton:focus {
  border: 1px solid var(--color-primary);
  color: var(--color-dark);
}

.card :global(.ant-card-body) {
  padding: 15px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .cardDate {
    font-size: 21px;
    line-height: 20px;
  }

  .cardIcon {
    font-size: 1.7rem;
  }

  .cardInfo {
    font-size: 20px;
    line-height: 20px;
    height: 80px;
    margin-top: 13px;
  }

  .cardTitleWrap {
    font-size: 20px;
    line-height: 20px;
    height: 45px;
  }

  .titleWrap{
    height: 50px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .cardDate {
    font-size: 16px;
    line-height: 15px;
  }

  .cardTitleWrap {
    font-size: 15px;
    line-height: 15px;
    border-bottom: 3px solid var(--color-dark);
    height: 35px;
  }

  .cardIcon {
    font-size: 1.1rem;
  }

  .cardInfo {
    font-size: 15px;
    line-height: 15px;
    height: 60px;
    margin-top: 10px;
  }

  .cardCategory {
    font-size: 15px;
    line-height: 18px;
  }

  .cardButton {
    font-size: 15px;
    padding: 16px 19px;
    margin-bottom: 10px;
  }

  .cardImage {
    height: 184px;
  }
}


@media screen and (max-width: 1499px) and (min-width: 200px) {
  .cardDate {
    font-size: 11px;
    line-height: 12px;
  }

  .cardCategory {
    font-size: 10px;
    line-height: 10px;
    width: 60%;
  }

  .cardImage {
    height: 100px;
  }

  .card {
    box-shadow: 0px 2px 10px var(--color-light-4);
    border-radius: 7px;
  }

  .card :global(.ant-card-body) {
    padding: 8px;
  }

  .cardTitleWrap {
    font-size: 10px;
    line-height: 12px;
    border-bottom: 2px solid var(--color-dark);
    height: 30px;
  }

  .cardIcon {
    font-size: 0.8rem;
  }

  .cardInfo {
    font-size: 10px;
    line-height: 12px;
    height: 40px;
    margin-top: 8px;
    /*margin-bottom: 10px;*/
  }

  .cardButton {
    font-size: 12px;
    line-height: 10px;
    border-radius: 5px;
    padding: 0 5px;
    margin-bottom: 0px;
    height: 26px;
  }

  .titleWrap {
    height: 25px;
  }

  .card :global(.ant-card-cover img){
    border-radius: 7px 7px 0px 0px;
  }
}