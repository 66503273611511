.chart {
    height: 800px !important;
    background-color: var(--color-light);
}
  
  @media screen and (max-width: 1949px) and (min-width: 1500px) {
    .chart {
        height: 550px !important;
    }
  }
  
  @media screen and (max-width: 1499px) and (min-width: 200px) {
    .chart {
        height: 385px !important;
    }

  }
  