.container {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.tooltipContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customIcon{
  font-size: 1.6rem;
  position: absolute;
  right: 11px;
  top: 8px;
  cursor: not-allowed;
}

.input {
  background: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  height: 60px;
}

.input :global(.ant-input-number-input){
  height: 60px;
}

.input input {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px !important;
  line-height: 18px;
  display: flex;
  align-items: center;
  color:var(--color-input-text);
  overflow: hidden;
  text-overflow: ellipsis;
}

.input:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-color: var(--color-light-2);
 
}

.input:global(.ant-input-number-disabled){
  background:transparent;
  border-color: var(--color-light-2);
  box-shadow: none;
}

.input:global(.ant-input-number){
  width: 100%;
}

.input :global(.ant-input-number-handler-wrap){
  display: none;
}

.input :global(.ant-input-suffix){
  font-size: 1.6rem;
}

.input:global(.ant-input-affix-wrapper-disabled) {
  background: transparent;
}

.input:global(.ant-input-affix-wrapper) {
  padding: 5.4px 11px;
}

.input input:disabled {
  color: var(--color-dark);
  padding-right: 0.3rem;
}

.input:global(.ant-input-affix-wrapper) {
  border: 1px solid var(--color-light-2);
}

.input:global(.ant-input-affix-wrapper-disabled) {
  border:1px solid var(--color-light-2);
  box-shadow: none;
}

.tooltip :global(.ant-tooltip-inner) {
  color: var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
}

.icon {
  color: var(--color-primary) !important;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .input, .input :global(.ant-input-number-input) {
    height: 45px;
  }

  .input input {
    font-size: 20px !important;
  }
  .input :global(.ant-input-suffix){
    font-size: 1rem;
  }

  .customIcon{
    font-size: 1rem;
  }

  .icon {
    font-size: 1.7em;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .input, .input :global(.ant-input-number-input) {
    height: 35px;
  }

  .input input {
    font-size: 15px !important;
  }

  .input :global(.ant-input-suffix){
    font-size: 1.1rem;
  }

  .customIcon{
    font-size: 1.1rem;
    top:4px
  }

  .icon {
    font-size: 1.5em;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .input, .input :global(.ant-input-number-input) {
    height: 30px;
    border-radius: 5px;
  }

  .input input {
    font-size: 10px !important;
  }

  .input :global(.ant-input-suffix){
    font-size: 0.8rem;
  }
  .customIcon{
    font-size: 0.8rem;
    top:2px
  }

  .icon {
    font-size: 1.1em;
  }

}
