.container {
  font-size: 0.9rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 2rem 0;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 72px;
  height: 100vh;
  width: 426px;
  padding-bottom: 100px;
}


@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .container {
    width: 380px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .container {
    width: 300px;
    padding-top: 30px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .container {
    width: 213px;
    padding-top: 18px;
    padding-right: 15px;
    padding-left: 13px;
  }
}


.tabs,
.buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.filters {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
}

.tabs {
  gap: 1.3rem;

}

.buttons {
  margin-top: 60px;
  gap: 0.5rem;
}

.active {
}

.tab {
  height: 60px;
  padding-left: 19px;
  padding-right: 19px;
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  background: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 4px;
  user-select: none;
  border: 1px solid var(--color-light-2);
}

.tab:hover {
  background: var(--color-secondary-2);
}

.tab.active {
  background-color:var(--color-primary);
  color: var(--color-light);
  cursor: initial;
  user-select: none;
}

.tab .icon {
  font-size: 40px;
  fill: var(--color-dark-2);
}

.tab.active .icon {
  fill: var(--color-button-text);
}

.tab .caption {
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 18px;
  flex: 1 1 auto;
  white-space: break-spaces;
  color: var(--color-dark);
  margin-left: 19px;
}

.tab.active .caption {
  color: var(--color-light);
}

@media (max-width: 1300px) {
  /*.tab {*/
  /*  padding-left: 10px;*/
  /*  padding-right: 10px;*/
  /*  height: 40px;*/
  /*  min-width: 125px;*/
  /*}*/
  .tab .icon {
    font-size: 30px;
  }

  .tab .caption {
    margin-left: 10px;
  }
}

.tab.active .caption {
  color:var(--color-button-text);
}

.caption {
  line-height: 40px;
  color:var(--color-dark);
}

.button {
  background-color:var(--color-primary);
  height: 40px;
  box-shadow: 2px 2px 10px var(--color-light-4);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.button:hover {
  background: var(--color-primary);
}

.button:active {
  box-shadow: none;
}

.button_label {
  line-height: 40px;
  color:var(--color-light);
}

.wrapepr {
  position: relative;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .container {
    width: 380px;
    padding-top: 50px;
  }

  .tab {
    height: 55px;
  }

  .tab .caption {
    font-size: 17px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .container {
    width: 300px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tab {
    height: 40px;
  }

  .tab .caption {
    font-size: 12px;
    line-height: 15px;
  }

  .tab .icon {
    font-size: 25px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .container {
    width: 213px;
    padding-top: 18px;
    padding-right: 12px;
    padding-left: 11px;
    padding-bottom: 20px;
  }

  .tab {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tab .caption {
    font-size: 8px;
    line-height: 11px;
  }

  .tab .icon {
    font-size: 20px;
  }
}