.header {
    display: flex;
    justify-content: space-between;

}

.logo {
    width: 100%;
    /* max-width: 180px; */
    display: flex;
    align-items: center;
}

.logo img {
    max-width: 240px;
    height: 50px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
    .header {
        height: 90px;
    }

    .logo img {
        height: 40px;
        width: 190px;
    }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
    .header {
        height: 80px;
    }

    .logo img {
        height: 35px;
        width: 160px;
    }
}

@media screen and (max-width: 1499px) and (min-width: 1300px) {
    .header {
        height: 70px;
    }

    .logo img {
        height: 25px;
        width: 110px;
    }
}

@media screen and (max-width: 1299px) and (min-width: 200px) {
    .header {
        height: 70px;
    }

    .logo img {
        height: 25px;
        width: 110px;
    }
}


