.tooltip {
  display: flex;
  height: 550px;
  width: 850px;
  flex-direction: column;
  padding: 10px;
}

.wrapIntegralIndicators {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.wrapperItem {
  display: flex;
  flex-direction: column;
}

.date {
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}

.wrapGroupTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 20px;
  margin-left: 13px;
}

.wrapColorAndLabel{
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-left: 28px;
}

.wrapPriority {
  display: flex;
  justify-content: space-between;
  width: 330px;
  margin-bottom: 20px;
  margin-left: 80px;
  font-family: var(--main-font-family);
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  width: 410px;
  font-family: var(--main-font-family);
}



@media screen and (max-width: 2559px) and (min-width: 1950px) {

  .tooltip {
    height: 500px;
    width: 810px;
    padding: 10px;
  }

  .wrapColorAndLabel{
    margin-left: 20px;
  }

  .wrapPriority {
    width: 340px;
    margin-bottom: 15px;
    margin-left: 53px;
  }

  .titleWrap {
    width: 395px;
    margin-bottom: 5px;
  }

  .wrapIntegralIndicators {
    padding-bottom: 20px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .tooltip {
    height: 410px;
    width: 640px;
    padding: 5px;
  }

  .wrapColorAndLabel{
    margin-left: 15px;
    font-size: 15px;
  }

  .wrapPriority {
    width: 280px;
    margin-bottom: 7px;
    margin-left: 33px;
  }

  .titleWrap {
    width: 310px;
    margin-bottom: 5px;
  }

  .date {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .wrapIntegralIndicators {
    padding-bottom: 30px;
  }

  .wrapGroupTitle {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {

  .tooltip {
    height: 300px;
    width: 400px;
    padding: 5px;
  }

  .wrapColorAndLabel{
    margin-left: 5px;
    font-size: 10px;
    line-height: 15px;
    align-items: center;
  }

  .wrapPriority {
    width: 175px;
    margin-bottom: 0;
    margin-left: 15px;
  }

  .titleWrap {
    width: 190px;
    margin-bottom: 5px;
  }

  .date {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 5px;
  }

  
  .wrapGroupTitle {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .wrapIntegralIndicators {
    padding-bottom: 10px;
  }

}
