.wrapper {
  border: 1px solid var(--color-light-2);
  border-radius: 5px;
  width: 48%;
  margin-right: 25px;
  margin-bottom: 20px;
  padding: 23px 33px 23px 33px;
}

.wrapperTitle {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-light-2);
}

.title {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 20px;
  color: var(--color-dark);
  white-space: pre-line;
  max-width: 650px;
}

.sum {
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 18px;
  color: var(--color-additional-elements);
  white-space: nowrap;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.header {
  border-bottom: 2px solid var(--color-light-4);
}

.headerColumn {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-dark);
  position: relative;
  padding: 20px 16px 7px 16px;
  border-bottom: 2px solid var(--color-light-4);
  width: 15%;
}

.headerColumnRed {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-dark);
  position: relative;
  padding: 20px 16px 7px 16px;
  border-bottom: 2px solid var(--color-light-4);
  width: 15%;
}

.headerColumnBlue {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-dark);
  position: relative;
  padding: 20px 16px 7px 16px;
  border-bottom: 2px solid var(--color-light-4);
  width: 15%;
}

.headerColumnGreen {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-dark);
  position: relative;
  padding: 20px 16px 7px 16px;
  border-bottom: 2px solid var(--color-light-4);
  width: 15%;
}

.headerColumnPurple {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-dark);
  position: relative;
  padding: 20px 16px 7px 16px;
  border-bottom: 2px solid var(--color-light-4);
  width: 15%;
}

.headerColumnGreen:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 35%;
  width: 90%;
  height: 2px;
  margin-left: -30%;
  background: var(--color-bright-5);
}

.headerColumnBlue:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 35%;
  width: 90%;
  height: 2px;
  margin-left: -30%;
  background: var(--color-bright-10);
}

.headerColumnRed:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 35%;
  width: 90%;
  height: 2px;
  margin-left: -30%;
  background: var(--color-bright);
}

.headerColumnPurple::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 35%;
  width: 90%;
  height: 2px;
  margin-left: -30%;
  background: var(--color-bright-2);
}

.headerColumnDA::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 35%;
  width: 90%;
  height: 2px;
  margin-left: -30%;
  background: #8AEBC4;
}

.headerRow {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: var(--color-dark);
}

.row {
  border-bottom: 2px solid var(--color-light-4);
}

.cell {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: var(--color-additional-elements);
  padding: 12px 0 15px 0;
}

.row:last-child {
  border-bottom: none;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .title {
    font-size: 20px;
    max-width: 500px;
  }

  .wrapper {
    padding: 20px 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .sum {
    font-size: 21px;
  }

  .headerRow{
    font-size: 17px;
  }

  .cell{
    font-size: 17px;
  }

  .headerColumn, .headerColumnRed, .headerColumnBlue, .headerColumnGreen, .headerColumnPurple{
    font-size: 17px;
  }

  .title {
    font-size: 19px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .title {
    font-size: 15px;
    max-width: 400px;
  }

  .wrapper {
    padding: 10px 10px 10px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 49%;
  }

  .sum {
    font-size: 18px;
  }

  .headerColumn, .headerColumnRed, .headerColumnBlue, .headerColumnGreen, .headerColumnPurple{
    font-size: 14px;
    padding: 15px 5px 7px 5px;
  }

  .headerRow {
    font-size: 14px;
  }

  .cell {
    font-size: 13px;
    padding: 8px 0 10px 0;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .title {
    font-size: 10px;
    line-height: 10px;
    max-width: 220px;
  }

  .wrapperTitle {
    height: 25px;
    border-bottom: 1px solid var(--color-light-2);
  }

  .wrapper {
    padding: 10px 5px 5px 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .sum {
    font-size: 10px;
  }

  .header {
    border-bottom: 1px solid var(--color-light-4);
  }

  .headerColumn, .headerColumnRed, .headerColumnBlue, .headerColumnGreen, .headerColumnPurple {
    font-size: 9px;
    padding: 0;
    border-bottom: 1px solid var(--color-light-4);
  }

  .headerColumn:before, .headerColumnRed:before, .headerColumnGreen:before, .headerColumnBlue:before, .headerColumnPurple::before {
    height: 1px;
    bottom: -1px;
  }

  .headerRow {
    font-size: 9px;
    line-height: 12px;
  }

  .cell {
    font-size: 8px;
    padding: 0;
  }
}