.headerBlock {
  display: flex;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.tabContainer {
  padding-bottom: 0px;
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .tabContainer {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .tabContainer {
    padding-bottom: 0px;
  }
}
