@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Light.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-Light.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-Light.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-LightItalic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-LightItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-LightItalic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-Italic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-Italic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BoldItalic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Lato-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */
    url('fonts/Lato-BoldItalic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
