@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}