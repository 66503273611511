.collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    border-bottom: 3px solid var(--color-light-2);
    outline: none;
    display: flex;
    align-items: center;
    color: var(--color-dark);
    font-size: 25px;
    font-weight: 600;
    line-height: 18px;
    padding: 0;
    margin-bottom: 16px;
}

.collapse:global(.ant-collapse) {
    background-color: transparent;
    border: none;
    width: 100%;
}

:global(.ant-collapse-header-text){
    color: var(--color-dark) !important;
}

.collapse:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-top: 0 !important;
    padding-left: 0px;
    padding-right: 5px;
}

.collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
    font-size: 3rem;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
        font-size: 2.5rem;
    }

    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
        font-size: 20px;
    }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
        font-size: 2rem;
    }

    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
        font-size: 15px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
        font-size: 1rem;
    }

    .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
        font-size: 10px;
        border-bottom: 2px solid var(--color-light-2);
        margin-bottom: 10px;
    }

    .collapse:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
        padding-left: 2px;
    }

    .collapse:global(.ant-collapse) {
        padding-right: 10px;
    }
}