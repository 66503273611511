.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  height: 116px;
  padding: 31px 0px 28px 0px;
  z-index: 1000;
  position: relative;
  background: var(--color-light);
}

.formItem {
  width: 100%;
  align-self: end;
}

.userName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo {
  width: 18%;
  min-width: 150px;
  max-width: 18%;
  border-right: 1px solid var(--color-light-4);
  height: 116px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.tooltip :global(.ant-tooltip-inner) {
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  border-radius: 10px;
  width: 350px;
  padding: 0;
  max-width: 350px;
}

.tooltipNotifs :global(.ant-tooltip-inner) {
  border: 1px solid var(--color-additional-elements);
  box-sizing: border-box;
  border-radius: 10px;
  width: 450px;
  padding: 0;
  max-width: 450px;
}

.inputWrap {
  font-size: 25px;
  line-height: 18px;
  margin-top: 40px;
}

.link{
  border-bottom: 1px solid var(--color-primary);
  color:var(--color-primary) !important;
}

.wrapperNotifs {
  padding: 20px;
  width: 100%;
  height: 100%;
  max-height: 215px;
  overflow-y: auto;
  color: var(--color-dark);
  font-family: var(--main-font-family);
}

.wrapperNotifsTrigger {
  position: relative;
}

.newNotifs {
  position: absolute;
  bottom: -4px;
  right: 4px;
  height: 20px;
  width: 20px;
  background: var(--color-bright);
  color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 12px;
}

.notificationWrapper {
  position: relative;
}

.notificationWrapper::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: -20px;
  height: 4px;
  width: 300px;
  background-color: var(--color-dark-2);
  border-radius: 0 2px 2px 0;
}

.notificationWrapper:last-child::after {
  content: none;
}

.wrapperAccount {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.button {
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 32px;
  color:var(--color-title);
  width: 100%;
  height: 55px;
  padding: 0 25px 0 14px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button:hover {
  color:var(--color-title);
  background: var(--color-light-2);
}

.lk {
  color: var(--color-title);
  font-size: 30px;
  line-height: 35px;
  white-space: nowrap;
  width: 70%;
}

.lk>span {
  display: flex;
  align-items: center;
}

.header .lk,
.header :global(.anticon) {
  cursor: pointer;
  user-select: none;
}

.header :global(.anticon):hover {
  color: var(--color-primary) !important;
  border: none !important;
}

.icon {
  font-size: 2.3rem;
  margin-right: 24px;
  margin-left: 24px;
}

.lk > span > .icon {
  margin-left: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 102px;
}

.iconLang {
  margin-left: -100px;
}

.input:global(.ant-select .ant-select-selector) {
  background: transparent;
  box-shadow: none;
  border: none;
  font-size: 30px;
  line-height: 18px;
  height: 30px;
}

.input :global(.ant-select-arrow .anticon:not(.ant-select-suffix)) {
  pointer-events: none;
}

.input:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector){
  padding-left: 0;
}

.input:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
  background: transparent;
  box-shadow: none;
  border: none;
}


.input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
  color:var(--color-title);
}

.input:global(.ant-select-single.ant-select-show-arrow.ant-select-disabled .ant-select-selection-item) {
  color: var(--color-dark-2);
}

.input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item){
  padding-right: 24px;
}

.input :global(.ant-select-selection-search .ant-select-selection-search-input) {
  color: var(--color-primary);
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
}

.input :global(.ant-select-arrow) {
  right: -100px;
  width: 1.6em;
  height: 1em;
  margin-top: -0.8em;
}

.input:global(.ant-select-disabled .ant-select-arrow) {
  font-size: 1rem;
}

.dropdown {
  width: auto !important;
  font-size: 30px;
  line-height: 18px;
  font-weight: var(--main-font-weight);
}

.dropdown:global(.ant-select-dropdown) {
  color: var(--color-dark-2);
  background: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 30px;
  line-height: 18px;
  font-weight: var(--main-font-weight);
}

.dropdown :global(.ant-select-item) {
  height: 50px;
  color: var(--color-title);
  background:var(--color-light);
  font-size: 30px;
  line-height: 18px;
}

.dropdown :global(.ant-select-item-option-content) {
  height: 30px;
  display: flex;
  align-items: center;
}

.dropdown :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover) {
  background: var(--color-light-2);
}

.dropdown :global(.ant-select-item-option-selected) {
  color: var(--color-dark-2);
}

.input:global(.ant-select-arrow .anticon:not(.ant-select-suffix)) {
  pointer-events: initial;
}


.iconLang {
  font-size: 2.5em;
}

.container {
  display: flex;
  width: 600px;
  align-items: center;
  margin-right: 40px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .header {
    height: 80px;
    gap: 40px;
    padding: 15px 0px 15px 0px;
  }

  .lk {
    font-size: 25px;
  }

  .search :global(.ant-input-prefix) {
    margin-right: 100px;
  }

  .input :global(.ant-select-arrow) {
    right: -50px;
    margin-top: -1em;
  }

  .icon {
    font-size: 1.4rem;
  }

  .input:global(.ant-select .ant-select-selector) {
    font-size: 25px;
  }

  .iconLang {
    font-size: 2em;
  }

  .tooltip :global(.ant-tooltip-inner) {
    width: 320px;
    margin-right: 10px;
  }

  .button {
    font-size: 25px;
    height: 45px;
  }

  .inputWrap {
    margin-top: 30px;
  }

  .logo {
    height: 80px;
  }

}

@media screen and (max-width: 2559px) and (min-width: 1024px) {
  .input :global(.ant-select-arrow) {
    right: -100px;
    margin-top: -0.7em;
  }

  .dropdown :global(.ant-select-item) {
    height: 40px;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 25px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 25px;
  }

  .inputWrap {
    font-size: 20px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .header {
    height: 70px;
    padding: 15px 0px 15px 0px;
    gap: 30px;
  }

  .container {
    max-width: 400px;
    min-width: 200px;
    margin-right: 30px;
  }

  .search :global(.ant-input-prefix) {
    margin-right: 100px;
  }

  .lk {
    font-size: 20px;
  }

  .input:global(.ant-select .ant-select-selector) {
    font-size: 20px;
  }

  .icon {
    font-size: 1.4rem;
    margin-right: 15px;
    margin-left: 15px;
  }

  .iconLang {
    font-size: 1.8em;
  }

  .dropdown :global(.ant-select-item) {
    height: 30px;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 20px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 20px;
  }

  .tooltip :global(.ant-tooltip-inner) {
    width: 290px;
    margin-right: 10px;
  }

  .button {
    font-size: 20px;
    height: 40px;
  }

  .inputWrap {
    margin-top: 20px;
    font-size: 15px;
  }

  .logo {
    height: 70px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .header {
    height: 60px;
    padding: 15px 0px 15px 0px;
    gap: 25px;
  }

  .search :global(.ant-input-prefix) {
    margin-right: 100px;
  }

  .lk {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .input:global(.ant-select .ant-select-selector) {
    font-size: 15px;
  }

  .icon {
    font-size: 1rem;
    margin-right: 5px;
    margin-left: 5px;
  }

  .iconLang {
    margin-top: 3px;
    font-size: 1.2em;
  }

  .container {
    width: 320px;
    margin-right: 25px;
  }

  .dropdown :global(.ant-select-item) {
    height: 25px;
  }

  .dropdown:global(.ant-select-dropdown) {
    font-size: 15px;
  }

  .dropdown :global(.ant-select-item) {
    font-size: 15px;
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
  }

  .tooltip :global(.ant-tooltip-inner) {
    margin-right: 10px;
    width: 200px;
  }

  .button {
    font-size: 15px;
    height: 29px;
  }

  .inputWrap {
    margin-top: 10px;
    font-size: 10px;
  }

  .notification {
    width: 160px;
    padding: 5px 10px 5px 10px;
  }

  .logo {
    height: 60px;
  }

  .input:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item){
    padding-right: 15px;
  }

}