.tooltip {
    border: 1px solid var(--color-light-2);
    box-sizing: border-box;
    background: var(--color-light);
    border: 1px solid var(--color-light-2);
    box-shadow: 4px 4px 20px var(--color-light-4);
    border-radius: 10px;
    padding: 20px 30px;
}

.header {
    font-weight: var(--main-font-weight);
    font-size: 20px;
    line-height: 20px;
    color: var(--color-dark);
    margin-bottom: 14px;
}

.param {
    font-size: 18px;
    line-height: 18px;
}
.param .param__values {

    margin-top: 20px;
}

.param__label {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
}

.param__label_caption {
    width: 200px;
}

.param__label_value {
    color:var(--color-additional-elements);
    font-weight: 500;
    width: 30px;
}

.param__values {
    display: flex;
    justify-content: space-between;
    color:var(--color-dark);
    font-style: normal;
    font-weight: 500;
    padding-left: 45px;
    margin-top: 10px;
    margin-bottom: 20px;
}



.param_label {
    width: 200px;
}

.param_value {
    color:var(--color-additional-elements);
    width: 30px;
}

.paramTarget {
    margin-bottom: 20px;
}

@media screen and (max-width: 1949px) {

    .header {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 0px;
    }

    .param {
        font-size: 14px;
        line-height: 14px;
    }

    .param__label {
        margin-top: 7px;
    }

    .param__values {
        margin-top: 7px;
        margin-bottom: 15px;
        padding-left: 33px;
    }

    .param_label,
    .param__label_caption {
        width: 175px;
    }
}