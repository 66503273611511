.container {
  padding: 20px 40px;
  overflow: scroll;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: var(--main-font-weight);
}

.title .secondary {
  color: var(--color-dark);
}

.pagination {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 0px;
}

.searchBlockWrapper {
  margin-bottom: 25px;
}