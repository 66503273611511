.systemWrapper {
  margin-bottom: 20px;
}

.systemWrapper:last-child {
  margin: 0;
}

.systemBlock {
  border: 1px solid rgba(64, 103, 191, 0.3);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

}


.systemTitle {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 18px;
}

.event {
  padding-right: 8px;
  border-bottom: 1px solid var(--color-additional-elements);
  flex: 0 0 48%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.event:first-child {
  border-top: 1px solid var(--color-additional-elements);
}

.event:nth-child(2) {
  border-top: 1px solid var(--color-additional-elements);
}

.event:nth-child(2)::before {
  content: '';
  position: absolute;
  width: 1px;
  left: 50%;
  top: 8px;
  bottom: 8px;
  background-color: var(--color-additional-elements);
}

.wrap {
  margin-left: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  height: 30px;
  white-space: nowrap;
}

.wrap :global(.ant-checkbox-wrapper) {
  display: flex;
  align-items: end;
  height: 30px;
}

.wrap :global(.ant-checkbox-wrapper)::after {
  display: none;
}

.label {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px;
  line-height: 18px;
  color: var(--color-dark);
  vertical-align: middle;

}

.wrap :global(.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled) .label {
  color: var(--color-dark-2);
}

.icon {
  color: var(--color-additional-elements);
  font-size: 2em;
}

:global(.ant-form) .wrap :global(label) {
  font-size: 1rem;
  gap: 10px;
  align-items: center;
}

.wrap :global(.ant-checkbox) {
  top: unset;
}

.wrap :global(.ant-checkbox + span) {
  padding-left: 0;
  padding-right: 0;
}

:global(.ant-form-item) .wrap :global(input[type='checkbox']) {
  width: 1.5rem;
  height: 1.5rem;
}

.wrap :global(.ant-checkbox-inner) {
  background: var(--color-light);
  border: 1px solid var(--color-input-text);
  box-sizing: border-box;
  box-shadow: 4px 4px 5px var(--color-light-4);
  border-radius: 0.3rem;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.wrap :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
  content: '';
  background: var(--color-additional-elements);
  mask-image: url("../../../images/checkbox.svg");
  mask-size: cover;
  mask-position: center;
  width: 1rem;
  height: 1rem;
  top: 0.15rem;
  left: 0.18rem;
  transform: none;
  transition: none;
  border: none;
}

.wrap :global(.ant-checkbox-checked::after) {
  border: none;
}

.wrap :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--color-input-text);
}

.wrap :global(.ant-checkbox-disabled .ant-checkbox-inner) {
  border-color: var(--color-input-text) !important;
}

.tooltip :global(.ant-tooltip-inner) {
  color:var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-input-text);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .label {
    font-size: 20px;
  }
  .icon {
    font-size: 1.7em;
  }

  .wrap :global(.ant-checkbox-inner ){
    width: 23px;
    height:23px;
  }

  .wrap :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    width: 0.8rem;
    height: 0.8rem;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .label {
    font-size: 15px;
  }
  .icon {
    font-size: 1.5em;
  }

  .tooltip :global(.ant-tooltip-inner) {
    font-size: 15px;
  }

  .wrap :global(.ant-checkbox-inner ){
    width: 21px;
    height:21px;
  }

  .wrap :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    width: 0.7rem;
    height: 0.7rem;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .wrap :global(.ant-checkbox-inner ){
    width: 15px;
    height:15px;
    top:-0.05em;
  }

  .label {
    font-size: 10px;
  }

  .icon {
    font-size: 1.1em;
  }

  .wrap :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    width: 0.5rem;
    height: 0.5rem;
    left: 0.1rem;
  }

  .tooltip :global(.ant-tooltip-inner) {
    font-size: 8px;
    line-height: 13px;
  }
}
