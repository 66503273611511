.wrapper {
  padding: 10px 40px 0px 40px;
  overflow: scroll;
  height: 100%;
  position: relative;
  border-top: 1px solid var(--color-light-4);
}

.wrapDate {
  position: absolute;
  z-index: -1000;
  font-size: 25px;
  color: var(--color-dark);
  top: -105px;
}

.emptyKpiChart {
  margin-top: 40px;
  padding-bottom: 65px;
  background: url('../../images/emptyKpiChart.svg'), #FFF;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 860px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 18px;
  color: #B0B7BD;
}

.wrapperTitle {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  background-color: var(--color-light);
}

.titleContainer {
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: var(--color-light);
}

.count {
  margin-left: 5px;
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 20px;
  color:var(--color-additional-elements);
  background-color: var(--color-light);
}

.title {
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 20px;
  color:var(--color-dark);
  white-space: nowrap;
  background-color:var(--color-light);
}
.wrapperPie {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  border-radius: 10px;
  padding: 29px 45px 30px 55px;
  height: 900px;
  background-color:var(--color-light);
}

.wrapperIntegral {
  display: flex;
  flex-wrap: wrap;
}

.form {
  display: flex;
  margin-top: 25px;
  flex-wrap: wrap;
  width: 100%;
}

.labelInput {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 15px;
  color: var(--color-dark);
  margin-top: 19px;
}

.inputWrap {
  width: 20%;
  margin-right: 39px;
  align-self: end;
}

.wrapperPie {
  display: flex;
  justify-content: space-around;
  border: 1px solid var(--color-light-2);
  border-radius: 10px;
  padding: 29px 45px 30px 55px;
  background-color:var(--color-light);
}

.panelTable {
  margin-bottom: 40px;
}

.tableKpi colgroup {
  border-right: 1px solid var(--color-light-2);
}



@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .wrapper {
    padding: 10px 40px 0px 40px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .wrapperPie {
    padding: 20px 30px 20px 35px;
    height: 650px;
  }

  .emptyKpiChart {
    height: 650px;
  }

  .wrapper {
    padding: 10px 30px 0px 30px;
  }

  .labelInput {
    font-size: 15px;
  }

  .title {
    font-size: 18px;
  }

  .panelTable {
    margin-bottom: 10px;
  }

  .form {
    margin-top: 20px;
  }

  .count {
    font-size: 18px;
  }

  .wrapperTitle {
    margin-top: 15px;
    background-color: var(--color-light);
  }
}

@media screen and (max-width: 1499px) and (min-width: 1300px) {
  .emptyKpiChart {
    height: 500px;
  }
}

@media screen and (max-width: 1299px) and (min-width: 1100px) {
  .emptyKpiChart {
    height: 400px;
  }
}

@media screen and (max-width: 1099px) and (min-width: 200px) {
  .emptyKpiChart {
    height: 300px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .wrapperPie {
    padding: 15px 20px 10px 20px;
    height: 450px;
  }

  .wrapper {
    padding: 10px 25px 0px 25px;
  }

  .labelInput {
    font-size: 10px;
  }

  .panelTable {
    margin-bottom: 0;
  }

  .form {
    margin-top: 0;
  }

  .title {
    font-size: 12px;
  }

  .count {
    font-size: 12px;
  }

  .wrapperTitle {
    margin-top: 8px;
  }
}