.input {
  background: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  border: 1px solid var(--color-light-2);
  height: 60px;
}

.input input {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--color-input-text);
}

.container {
  display: flex;
  align-items: center;
}

.info{
  margin-left: 5px;
}

.input:global(.ant-picker) {
  padding: 2.4px 11px 2.4px;
}

.input:global(.ant-picker-disabled) {
  padding: 2.4px 11px 2.4px;
  border:1px solid var(--color-light-2);
  box-shadow: none;
  background: transparent;
}

.icon {
  font-size: 2em;
}

.input :global(.ant-form-item-has-error .ant-picker:not([disabled])){
  background-color: var(--color-light);
  border:1px solid var(--color-bright-9);
}

.input :global(.ant-picker-range-separator) {
  background-image: url("../../../images/datePicker.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 13px;
}

.input :global(.ant-picker-range-separator .anticon svg) {
  display: none;
}

.input:global(.ant-input-affix-wrapper-disabled) {
  background: var(--color-light-4);
  border-color: var(--color-light-2);
}

.input input:disabled {
  color: var(--color-dark);
  padding-right: 0.3rem;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .input {
    height: 45px;
  }

  .input input {
   font-size: 20px;
  }

  .input :global(.ant-picker-range-separator) {
    font-size: 10px;
    background-size: 16px 4px;
    padding: 0;
  }

  .icon {
    font-size: 1.3em;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .input {
    height: 35px;
  }

  .input input {
    font-size: 15px;
  }

  .input :global(.ant-picker-range-separator) {
    font-size: 10px;
    background-size: 16px 4px;
    padding: 0;
    margin-left: 10px;
  }

  .icon {
    font-size: 1.4em;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .input {
    height: 30px;
    border-radius: 5px;
  }

  .input input {
    font-size: 10px;
  }

  .input :global(.ant-picker-range-separator) {
    font-size: 10px;
    background-size: 12px 3px;
    padding: 0;
    margin-left: 10px;
  }
  .icon {
    font-size: 1em;
  }

  .input:global(.ant-picker-disabled) {
    padding: 2.4px 11px 2.4px;
  }

}


