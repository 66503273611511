.container {
  width: 100%;
  color: var(--color-dark);
  font-family: var(--main-font-family);
}

.date {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 400;
}

.title {
  margin-bottom: 5px;
  font-weight: 800;
  font-size: 16px;
}

.infoContainer {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-input-text);
}

