.wrapPage {
  width: 100%;
  height: 100%;
  position: relative;
}

.wrapContent {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-padding-top: 100px;
}

.wrapSection {
  padding-left: 40px;
  padding-right: 40px;
}

.navigatePanel {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--color-light-4);
  border-left: none;
  background-color: var(--color-light);
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 300;
  right: 0;
}

.elementNavigate {
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color:var(--color-primary);
  text-align: left;
}

.elementNavigate:hover {
  color: var(--color-primary);
}

.img {
  margin-top: 38px;
  border-radius: 15px;
  margin-bottom: 88px;
  object-fit: cover;
  width: 100%;
}

.info {
  background-color: var(--color-light);
  border-radius: 20px;
  width: 1048px;
  height: 579px;
  position: absolute;
  top: 314px;
  right: 23px;
  display: flex;
  flex-direction: column;
  padding: 89px 0 0 58px;
  color: var(--color-dark);
}

.titleInfo {
  font-weight: var(--main-font-weight);
  font-size: 50px;
  font-style: normal;
  line-height: 70px;
  padding-bottom: 21px;
  border-bottom: 5px solid var(--color-dark);
  max-width: 740px;
}

.descriptionInfo {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  padding-top: 53px;
  max-width: 779px;
}

.wrapTitle {
  margin-bottom: 71px;
}

.title {
  font-weight: var(--main-font-weight);
  font-size: 40px;
  line-height: 54px;
  border-bottom: 3px solid var(--color-primary);
  padding-bottom: 5px;
  width: max-content;
  margin-bottom: 50px;
}

.cardsContainer {
  display: grid;
  gap: 72px;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  justify-items: center;
}

.blockButton:hover {
  color: var(--color-light);
  border: 1px solid var(--color-primary);
  background-color:var(--color-primary);
}

.blockButton {
  margin: 0 auto;
  margin-top: 66px;
  font-weight: var(--main-font-weight);
  font-size: 26px;
  background-color:var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  color: var(--color-light);
  padding: 30px 70px;
  display: flex;
  align-items: center;
}

.wrapTitlePanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .categoryQuestion {
  font-weight: 500;
  font-size: 20px;
}

.infoQuestion {
  line-height: 20px;
  font-weight: 500;
  font-size: 20px;
  padding: 18px 0 18px 70px;
} */

.imageSupport {
  background-image: url("../../images/imageSup.svg");
  width: 100%;
  height: 565px;
  background-size: cover;
}

.noBg {
  background-image: none;
}

.supportInfo {
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 30px;
  width: 78%;
  height: 580px;
  background: var(--color-light);
  position: absolute;
  top: 200px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 65%;
}

.supportContactsWrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 100px;
}

.cardImage {
  object-fit: cover;
}

.supportContact {
  font-weight: var(--main-font-weight);
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  justify-content: end;
}

.supportContact:last-child {
  width: auto;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .title {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 45px;
  }

  .cardImage {
    width: 445px;
    height: 201px;
  }

  .cardDate {
    font-size: 20px;
    line-height: 20px;
  }

  .cardInfo {
    font-size: 20px;
    line-height: 20px;
    height: 90px;
    margin-top: 25px;
    margin-bottom: 60px;
  }

  .cardTitleWrap {
    font-size: 20px;
    line-height: 20px;
    height: 50px;
  }

  .cardsContainer {
    gap: 60px;
  }

  .card {
    width: 445px;
  }

  .imageSupport {
    height: 450px;
  }

  .supportContact {
    font-size: 25px;
    width: 40%;
    padding-bottom: 60px;
  }

  .supportInfo {
    height: 510px;
    top: 160px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .infoQuestion {
    font-size: 15px;
    padding: 15px 0 15px 50px;
  }

  .elementNavigate {
    font-size: 15px;
    line-height: 18px;
  }

  .navigatePanel {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .title {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 40px;
  }

  .wrapSection {
    padding: 0 30px;
  }

  .cardsContainer {
    gap: 42px;
  }

  .categoryQuestion {
    font-size: 15px;
  }

  .imageSupport {
    height: 330px;
  }

  .cardCategory {
    font-size: 15px;
  }

  .supportContact {
    width: 40%;
    padding-bottom: 40px;
    font-size: 15px;
    line-height: 17px;
  }

  .supportInfo {
    height: 340px;
    width: 70%;
    top: 130px;
  }

  .supportContactsWrap {
    padding-bottom: 45px;
  }

  .support {
    height: 70%;
  }

  .blockButton {
    margin-top: 25px;
    font-size: 16px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .infoQuestion {
    font-size: 11px;
    line-height: 15px;
    padding: 0px 0 0px 30px;
  }

  .supportContact {
    width: 40%;
    padding-bottom: 25px;
    font-size: 12px;
    line-height: 17px;
  }

  .elementNavigate {
    font-size: 8px;
    line-height: 10px;
  }

  .navigatePanel {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .title {
    font-size: 24px;
    line-height: 21px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--color-primary);
  }

  .wrapSection {
    padding: 0 25px;
  }

  .titleWrap {
    height: 30px;
  }

  .blockButton {
    margin-top: 17px;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px 25px;
    height: 26px;
  }

  .wrapTitle {
    margin-bottom: 20px;
  }

  .img {
    margin-top: 38px;
    height: 599px;
    margin-bottom: 20px;
  }

  .categoryQuestion {
    font-size: 10px;
  }

  .imageSupport {
    height: 215px;
  }

  .supportInfo {
    top: 70px;
    box-shadow: 2px 2px 10px var(--color-light-4);
    border-radius: 15px;
    max-width: 70%;
    min-width: 670px;
    height: 250px;
  }

  .supportContactsWrap {
    padding-bottom: 50px;
  }

  .support {
    height: 60%;
  }

  .cardsContainer {
    gap: 26px;
  }
}