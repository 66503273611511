.wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  color:var(--color-dark-2);
  height: 30px;
}

.wrap label {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
  line-height: 18px;
}

.switch {
  box-shadow: 5px 5px 10px 3px var(--color-dark-8);
  border-radius: 17px;
  background-color: var(--color-light);
  filter: drop-shadow(5px 5px 20px var(--color-light-4));
  width: 60px;
  height: 30px;
  border: none;
}

.switch:global(.ant-switch-checked .ant-switch-handle) {
  left: calc(100% - 25px - 2px);
}

.switch :global(.ant-switch-handle:before) {
  background-color:var(--color-primary);
  width: 24px;
  height: 24px;
  border-radius: 17px;
}

.active {
  color: var(--color-primary);
}

.row {
  user-select: none;
  white-space: nowrap;
}

.info {
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {

  .wrap label {
    font-size: 20px;
  }

  .switch {
    width: 50px;
    height: 25px;
  }

  .switch :global(.ant-switch-handle:before) {
    width: 20px;
    height: 20px;
  }

  .switch:global(.ant-switch-checked .ant-switch-handle) {
    left: calc(100% - 21px - 2px);
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {

  .wrap label {
    font-size: 15px;
  }

  .switch {
    width: 40px;
    height: 20px;
  }

  .switch :global(.ant-switch-handle:before) {
    width: 15px;
    height: 15px;
  }

  .switch:global(.ant-switch-checked .ant-switch-handle) {
    left: calc(100% - 15px - 2px);
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {

  .switch:global(.ant-switch){
    min-width: 30px;
  }

  .wrap label {
    font-size: 9px;
  }

  .switch {
    width: 30px;
    height: 15px;
  }

  .switch :global(.ant-switch-handle:before) {
    width: 12px;
    height: 12px;
    margin-top: -0.5px;

  }

  .switch:global(.ant-switch-checked .ant-switch-handle) {
    left: calc(100% - 11px - 2px);
  }
}
