html {
  font-size: 18px;
  min-height: 100%;
  position: relative;
}

@media (max-width: 1300px) {
  .app:not(:global(.guest)) .sider :global(.ant-layout-sider-children) {
    max-width: 213px;
  }
}

body {
  height: 100%;
}

ul {
  margin-bottom: 0;
}

.app {
  width: 100%;
  height: 100vh;
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
  color:var(--color-primary);
  line-height: 1.2;
}

.app :global(.ant-layout) {
  background: var(--color-light);
}

.app .layout {
  height: calc(100vh - 116px) !important;
  overflow: hidden;
}

.app :global(.ant-layout-sider) {
  background: var(--color-light);
}

.sider {
  z-index: 999;
  flex: auto !important;
  width: 18% !important;
  min-width: 150px !important;
  max-width: 18% !important;
}

.content {
  background: var(--color-background);
}

.app:not(:global(.guest)) .sider :global(.ant-layout-sider-children) {
  border-right: 1px solid var(--color-light-4);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.footer {
  min-height: calc(100vh / 16);
  position: absolute;
  bottom: 0;
  left: 0;
  background-color:var(--color-primary);
  color: var(--color-light);
  z-index: 4000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.footer .col {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer .col a {
  color: var(--color-light);
  text-decoration-line: underline;
}

.logo {
  background-image: url("./images/logoWhite.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 160px;
  height: 35px;
  background-position: center;
}

.image {
  background-image: url("./images/image.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 30px;
  background-position: center;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .app .layout {
    height: calc(100vh - 80px) !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .app .layout {
    height: calc(100vh - 80px) !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .app :global(.app-content) {
    margin-bottom: 10px;
  }

  .app .layout {
    height: calc(100vh - 80px) !important;
    overflow: hidden;
  }
}


@media screen and (max-width: 1199px) and (min-width: 200px) {
  .app .layout {
    height: calc(100vh - 60px) !important;
    overflow: hidden;
  }
}