.mainContent {
  height: calc(100% - 100px);
  padding: 0px 40px 60px 40px !important;
}

.collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
  border-bottom: 3px solid var(--color-light-2);
  outline: none;
  display: flex;
  align-items: center;
  color: var(--color-dark);
  font-size: 22px;
  line-height: 18px;
  padding: 0;
  margin-bottom: 16px;
}

.collapse:global(.ant-collapse) {
  background-color: transparent;
  border: none;
  width: 100%;
}

.collapse:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
  padding-top: 0 !important;
  padding-left: 0px;
  padding-right: 5px;
}

.collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
  font-size: 3rem;
}

.header {
  background-color: var(--color-background);
  padding: 0px 40px 15px 40px !important;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.panelWithBlocks {
  border: 1px solid rgba(64, 103, 191, 0.3);
  padding: 25px 0;
  margin-bottom: 25px;
  border-radius: 10px;
}

.hint {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #4F4F4F; 
  margin:12px 25px 0 25px;
}

.legend {
  color: #4F4F4F;
  line-height: 20px;
  font-size: 36px;
  font-weight: var(--main-font-weight);
  margin-left: 25px;
  margin-bottom: 25px;
}

.panelWithBlocks:last-child {
  margin-bottom: 0;
}

.readOnly {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  /*margin-top: 25px;*/
}

.readOnlyBlock{
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px 2px var(--color-light-4);
  background: var(--color-light);
  border-radius: 10px;
  padding: 20px;
  max-height: 360px;
  overflow-y: auto;
  flex: 1 0 30%;
  scroll-padding: 20px;
}

.readOnlyBlock:first-child {
  margin-left: 29.5px;
}

.readOnlyBlock:nth-child(3) {
  margin-right: 29.5px;
}

.readOnlyBlockOne {
  margin-right: 29.5px;
}

.activeItems {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%
}

.tooltip {
  color: var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  max-width: 400px;
  width: max-content;
  background-color: var(--color-light) !important;
}

.label {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px;
  line-height: 18px;
  color:var(--color-dark);
}

.readOnlyLabel{
  margin-right: 20px;
  margin-bottom: 0 !important;
  min-width: 200px;
  width: 200px;
  padding: 8px 0;
  white-space: nowrap;
  align-self: flex-start;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 22px;
  line-height: 18px;
  /* color: var(--color-primary); */
}

.readOnlyValue{
  color: var(--color-input-text);
  padding: 8px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  align-self: center;
  word-wrap: anywhere;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .legend {
    font-size: 30px;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
    font-size: 2.5rem;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    font-size: 20px;
  }

  .header {
    padding: 0px 40px 15px 30px !important;
  }

  .mainContent {
    padding: 0px 40px 60px 40px !important;
  }

  .readOnly {
    gap: 20px;
  }

  .readOnlyBlock {
    max-height: 330px;
  }

  .readOnlyLabel {
    margin-right: 15px;
    min-width: 160px;
    width: 160px;
    padding: 6px 0;
    font-size: 20px;
  }

  .label {
    font-size: 20px;
  }

  .readOnlyValue {
    padding: 6px 0;
    font-size: 20px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {

  .panelWithBlocks {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .legend {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .hint {
    font-size: 15px;
    line-height: 20px;
    margin:8px 0 0 0;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
    font-size: 2rem;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .mainContent {
    padding: 0px 30px 0px 30px !important;
  }

  .header {
    padding: 0px 30px 10px 39px !important;
  }

  .readOnly {
    /*margin-top: 20px;*/
    gap: 15px;
  }

  .readOnlyBlock {
    max-height: 320px;
    padding: 15px;
  }

  .readOnlyLabel {
    font-size: 15px;
    margin-right: 10px;
    min-width: 150px;
    width: 150px;
    padding: 5px 0;
  }

  .label {
    font-size: 15px;
  }

  .readOnlyValue {
    padding: 5px 0;
    font-size: 15px;
    line-height: 20px;
  }

  .readOnlyBlock:first-child {
    margin-left: 10px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {

  .panelWithBlocks {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .legend {
    font-size: 20px;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .hint {
    font-size: 10px;
    line-height: 15px;
    margin:10px 0 0 0;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg) {
    font-size: 1rem;
  }

  .collapse:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    font-size: 10px;
    border-bottom: 2px solid var(--color-light-2);
    margin-bottom: 10px;
  }

  .collapse:global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-left: 2px;
  }

  .collapse:global(.ant-collapse) {
    padding-right: 0px;
  }

  .header {
    background-color: var(--color-light);
    padding-bottom: 0;
  }

  .mainContent {
    padding: 0px 25px 0px 25px !important;
  }

  .header {
    padding: 0px 21px 5px 22px !important;
  }

  .readOnly {
    /*margin-top: 10px;*/
    gap: 7px;
  }

  .readOnlyBlock {
    max-height: 180px;
    padding: 10px;
    border-radius: 5px;
  }

  .readOnlyLabel {
    margin-right: 5px;
    min-width: 90px;
    width: 90px;
    padding: 0;
    font-size: 10px;
  }

  .label {
    font-size: 10px;
  }

  .readOnlyValue {
    padding: 0;
    font-size: 10px;
    line-height: 14px;
  }

  .readOnlyBlock:first-child {
    margin-left: 10px;
  }

  .readOnlyBlock:nth-child(3) {
    margin-right: 6px;
  }

  .readOnlyBlockOne {
    margin-right: 6px;
  }

}