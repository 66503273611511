.cascadeFilter :global(.ant-dropdown-menu) {
  border: 1px solid var(--color-dark-2);
  border-radius: 10px;
  background: var(--color-light-4);
  padding: 13px 0;
}

.cascadeFilter :global(.ant-dropdown-menu-submenu-title):hover {
  background: var(--color-light-2);
}

.cascadeFilter :global(.ant-dropdown-menu-submenu-title) {
  font-size: 22px;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  line-height: 30px;
}

:global(.ant-dropdown-menu-sub) {
  border: 1px solid var(--color-dark-2);
  border-radius: 10px;
  background: var(--color-light-4);
  padding: 13px 0;
}

:global(.ant-dropdown-menu-submenu-popup)>ul {
  margin: 0;
}

:global(.ant-typography){
  color: var(--color-dark);
}

:global(.ant-dropdown-menu-submenu-title) {
  font-size: 22px;
  color: var(--color-dark) !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
}

:global(.ant-dropdown-menu-submenu-selected) {
  background: var(--color-light-2);
}

:global(.ant-dropdown-menu-submenu-selected)>div>span {
  font-weight: var(--main-font-weight);
}

:global(.ant-space-item):first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.ant-dropdown-menu-submenu-title):hover {
  background: var(--color-light-2);
}

:global(.ant-dropdown-menu-submenu-active) {
  background: var(--color-light-2);
}

:global(.ant-dropdown-menu-title-content) {
  width: 299px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.ant-dropdown-menu-item) {
  font-size: 22px;
  line-height: 30px;
  color: var(--color-dark);
  width: 335px;
}

:global(.ant-dropdown-menu-item):hover {
  background: var(--color-light-2);
}

:global(.ant-dropdown-menu-item-selected) {
  background: var(--color-light-2);
  font-weight: var(--main-font-weight);
}

:global(.ant-dropdown-menu-submenu-placement-rightTop) {
  top: 88px !important
}

.search :global(.ant-input-prefix) {
  margin-right: 22px;
}

.iconDropDown {
  font-size: 28px;
}

:global(.ant-dropdown-menu-submenu-expand-icon) {
  font-size: 28px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .cascadeFilter :global(.ant-dropdown-menu) {
    padding: 8px 0;
  }

  .cascadeFilter :global(.ant-dropdown-menu-submenu-title) {
    font-size: 20px;
    line-height: 27px;
  }

  :global(.ant-dropdown-menu-submenu-title) {
    font-size: 20px;
    line-height: 27px;
  }

  :global(.ant-dropdown-menu-submenu-placement-rightTop) {
    top: 65px !important
  }

  :global(.ant-dropdown-menu-item) {
    font-size: 20px;
    line-height: 27px;
    width: 305px;
  }

  :global(.ant-dropdown-menu-sub) {
    padding: 8px 0;
  }

  .iconDropDown {
    font-size: 24px;
  }

  :global(.ant-dropdown-menu-submenu-expand-icon) {
    font-size: 24px;
  }

  :global(.ant-dropdown-menu-title-content) {
    width: 268px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  :global(.ant-dropdown-menu-title-content) {
    width: 205px;
    max-width: 205px;
  }

  :global(.ant-dropdown-menu-item) {
    font-size: 18px;
    height: 24px;
    width: 245px;
    line-height: 25px;
  }

  .cascadeFilter :global(.ant-dropdown-menu) {
    padding: 8px 0;
  }

  :global(.ant-dropdown-menu-sub) {
    padding: 8px 0;
  }

  .cascadeFilter :global(.ant-dropdown-menu-submenu-title) {
    font-size: 18px;
    height: 24px;
    line-height: 25px;
  }

  :global(.ant-dropdown-menu-submenu-title) {
    font-size: 18px;
    height: 24px;
    width: 245px;
    line-height: 25px;
  }

  :global(.ant-dropdown-menu-submenu-placement-rightTop) {
    top: 55px !important
  }

  .iconDropDown {
    font-size: 20px;
  }

  :global(.ant-dropdown-menu-submenu-expand-icon) {
    font-size: 20px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  :global(.ant-dropdown-menu-item) {
    font-size: 10px;
    line-height: 15px;
    height: 15px;
    width: 156px;
  }

  .cascadeFilter :global(.ant-dropdown-menu) {
    padding: 5px 0;
    border-radius: 5px;
  }

  :global(.ant-dropdown-menu-sub) {
    padding: 5px 0;
    border-radius: 5px;
  }

  .cascadeFilter :global(.ant-dropdown-menu-submenu-title) {
    font-size: 10px;
    line-height: 15px;
    height: 15px;
  }

  :global(.ant-dropdown-menu-submenu-title) {
    font-size: 10px;
    line-height: 15px;
    height: 15px;
  }

  :global(.ant-dropdown-menu-submenu-placement-rightTop) {
    top: 45px !important
  }

  :global(.ant-dropdown-menu-title-content) {
    max-width: 120px;
    width: 120px;
  }

  .iconDropDown {
    font-size: 12px;
  }

  :global(.ant-dropdown-menu-submenu-expand-icon) {
    font-size: 12px;
  }
}