.modal :global(.ant-modal-body) {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.modal :global(.ant-modal-content) {
  background-color: transparent;
}

.modal:global(.ant-modal) {
  max-width: 80% !important;
  width: 80% !important;
}

.icon {
  font-size: 2.5rem;
  position: absolute;
  top: 0px;
  right: 0px;
}

.modal :global(.ant-modal-close) {
  right: -50px;
  top: 0;
}

.modal :global(.ant-modal-content) {
  box-shadow: none;
}

.modal :global(.ant-modal-content) {
  position: initial;
}

.modal :global(.ant-modal-wrap) {
  left: -100px;
  top: -50px;
  border: 2px solid var(--color-bright-9);
}

.wrap {
  display: flex;
  justify-content: center;
  margin-left: 200px;
  margin-bottom: 200px;
  inset: 60px 0 0;
}

.image {
  width: 100%;
  object-fit: contain;
  height: 80vh;
  border-radius: 15px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .icon {
    font-size: 2.3rem;
  }

  .modal :global(.ant-modal-close) {
    right: -45px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .icon {
    font-size: 2rem;
  }

  .modal :global(.ant-modal-close) {
    right: -40px;
  }

}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .icon {
    font-size: 1.3rem;
  }

  .modal :global(.ant-modal-close) {
    right: -25px;
  }
}