.list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
}

.listInModal {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
}

.item {
  width: 300px;
  height: 164px;
  padding: 5px 7px;
  background: var(--color-light);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  cursor: default;
  border: 1px solid var(--color-light-2);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px 2px var(--color-light-4);
}

.icon {
  font-size: 1.5em;
}

.icon {
  font-size: 1.5em;
}

.item-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.item-row:last-child {
  flex: 1 0 auto;
}


.button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.buttonDisabled button:global(.ant-btn[disabled]) {
  border: 0px;
  background: none;
}

.buttonDisabled button:global(.ant-btn[disabled] .anticon rect) {
  fill: var(--color-dark-2);
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.withoutDownload {
  justify-content: flex-start;
}

.leftItem {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 10px;
}

.ext {
  position: absolute;
  color: var(--color-light);
  text-transform: uppercase;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2.3rem !important;
  left: 1.2rem !important;
  ;
  width: 2rem !important;
  height: 1rem !important;
  border-radius: 2px;
}

.size {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px !important;
  line-height: 30px;
  color: var(--color-dark);
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.name {
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 22px;
  white-space: nowrap;
  display: inline-block;
  max-width: 230px;
  color: var(--color-dark);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.file {
  font-size: 2.8rem !important;
  margin-right: 1rem;
}

.pdf {
  background-color: var(--color-bright);
}

.doc,
.docx {
  background-color: var(--color-primary);
}

.xls,
.xlsx {
  background-color: var(--color-bright-5);
}

.jpg,
.jpeg {
  background-color: var(--color-dark);
}

.ppt,
.pptx {
  background-color: var(--color-bright-6);
}

.txt,
.html {
  background-color:  var(--color-bright-8);
}

.png {
  background-color: var(--color-light-3);
}

.toSave {
  opacity: 0.50;
}


@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .item {
    width: 250px;
    height: 130px;
  }

  .icon {
    font-size: 1.2em !important;
  }

  .name {
    max-width: 180px;
  }

  .ext {
    top: 2rem !important;
    left: 0.8rem !important;
    ;
    width: 2rem !important;
    height: 1rem !important;
  }

  .leftItem {
    padding-top: 10px;
    padding-left: 3px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .item {
    width: 200px;
    height: 100px;
  }

  .icon {
    font-size: 1em !important;
  }

  .name {
    max-width: 150px;
    font-size: 15px !important;
  }

  .size {
    font-size: 15px !important;
  }

  .ext {
    top: 1.9rem !important;
    left: 0.8rem !important;
    ;
    width: 1.5rem !important;
    height: 0.6rem !important;
    font-size: 8px !important;
  }

  .leftItem {
    padding-top: 7px;
    padding-left: 3px;
  }

  .file {
    font-size: 2.2rem !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .item {
    width: 150px;
    height: 82px;
  }

  .icon {
    font-size: 0.8em !important;
  }

  .ext {
    top: 1.4rem !important;
    left: 0.8rem !important;
    ;
    width: 1.2rem !important;
    height: 0.5rem !important;
    font-size: 5px !important;
  }

  .file {
    font-size: 1.6rem !important;
  }

  .leftItem {
    padding-top: 3px;
    padding-left: 3px;
  }

  .name {
    max-width: 100px;
    font-size: 10px !important;
  }

  .size {
    font-size: 10px !important;
  }
}