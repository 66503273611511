.reset button {
  margin: 0 auto 0 auto;
  text-align: center;
}

.customFilters {
  margin-bottom: 25px;
}

.customFilters_hidden {
  height: 1px !important;
  margin-bottom: 0 !important;
}

.defaultSelectWidth {
  min-width: 250px !important;
}

.title {
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 40px;
  line-height: 15px;
  padding-bottom: 81px;
  padding-top: 20px;
  margin: 0;
}

.form{
  align-items: center;
  flex-wrap: nowrap;
}

.tooltip :global(.ant-tooltip-inner) {
  color:var(--color-dark);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  width: max-content;
}

.icon {
  font-size: 2em;
  margin: 0 15px;
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

.button {
  background: var(--color-light);
  border: 2px solid var(--color-primary);
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--color-primary);
  width: 88%;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  height: 60px;
  margin-top: 60px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button:hover {
  background: transparent;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.button:focus {
  background: transparent;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.wrap {
  display: flex;
  align-items: center;
}

.formItem:last-child {
  width: 90%;
}

.wrap:last-child {
  min-width: 350px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .title {
    font-size: 30px;
    padding-bottom: 51px;
  }

  .button {
    height: 50px;
    font-size: 20px;
  }

  .icon {
    font-size: 1.7em;
  }

  .wrap:last-child {
    min-width: 270px;
  }

  .customFilters {
    margin-bottom: 20px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .title {
    font-size: 25px;
    padding-bottom: 31px;
  }

  .button {
    height: 50px;
    font-size: 15px;
    min-width: 150px;
    margin-top: 24px !important;
  }

  .icon {
    font-size: 1.5em;
  }

  .customFilters {
   margin-bottom: 15px;
  }

  .wrap:last-child {
    min-width: 220px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .title {
    font-size: 15px;
    padding-bottom: 12px;
  }

  .button {
    height: 30px;
    font-size: 10px;
    min-width: 150px;
    border-radius: 5px;
    margin-top: 14px !important;
  }

  .reset button {
    margin: 0;
  }

  .icon {
    font-size: 1.1em;
  }

  .customFilters {
    margin-bottom:10px;
    width: 60%;
  }

  .wrap {
    width: 160px;
  }

  .formItem:last-child {
    width: 160px;
  }

  .formItem :global(.ant-form-item-control-input){
    width: 150px;
  }
  .form{
    height: 35px;
  }
}