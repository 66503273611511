.wrapper {
  /*margin-top: 21px;*/
  padding: 10px 40px 0px 40px;
  overflow: scroll;
  height: 100%;
  position: relative;
  border-top: 1px solid var(--color-light-4);
}

.wrapDate {
  position: absolute;
  z-index: -10000;
  font-size: 25px;
  color:var(--color-dark);
  top: -105px;
}

.wrapperChart {
  width: 100%;
}

.empty {
  margin-top: 40px;
  padding-bottom: 30px;
  background: url('../../images/reportEmpty.svg'), #FFF;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 860px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 18px;
  color: #B0B7BD;
}

.wrapperIntegral {
  display: flex;
  flex-wrap: wrap;
}

.form {
  display: flex;
  margin-top: 25px;
}

.labelInput {
  font-weight: var(--main-font-weight);
  font-size: 25px;
  line-height: 15px;
  color: var(--color-dark);
  margin-top: 19px;
}

.inputWrap {
  width: 20%;
  margin-right: 39px;
  align-self: end;
}

.wrapperPie {
  display: flex;
  justify-content: space-around;
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  padding: 29px 45px 30px 55px;
}

.panelTable {
  margin-bottom: 40px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .empty {
    height: 700px;
  }

  .wrapper{
    padding: 10px 40px 0px 40px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .wrapperPie {
    padding: 20px 30px 20px 35px;
  }


  .labelInput {
    font-size: 15px;
  }

  .panelTable {
    margin-bottom: 10px;
  }

  .form {
    margin-top: 20px;
  }

  .empty {
    height: 600px;
  }

  .wrapper {
    padding: 10px 30px 0px 30px;
  }
}


@media screen and (max-width: 1499px) and (min-width: 1300px) {

  .empty {
    height: 500px;
  }
}

@media screen and (max-width: 1299px) and (min-width: 200px) {

  .empty {
    height: 400px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .wrapperPie {
    padding: 15px 20px 10px 20px;
  }

  .labelInput {
    font-size: 10px;
  }

  .panelTable {
    margin-bottom: 0;
  }

  .form {
    margin-top: 0;
  }

  .wrapper {
    padding: 10px 25px 0px 25px;
  }

}