.table :global(.ant-table-content){
  border-left: none;
  border-top: none;
  border-bottom: none;
  padding: 0px;
  margin-top: 10px;
}

.wrap {
  border: 1px solid var(--color-light-2);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 10px;
  padding: 20px;
}

.table :global(.ant-table-thead){
  border-top: 1px solid var(--color-light-2);
}

.table :global(.ant-table-thead > tr > th) {
  background-color: unset;
  border: none;
  border-bottom: 1px solid var(--color-light-2);
  padding: 15px 12px;
  font-style: normal;
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-dark);
}

.table :global(.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
  background-color: transparent;
}

.table :global(.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after) {
  box-shadow: none;
}

.columnMonth {
  padding: 10px !important;
  border-bottom: 1px solid var(--color-light) !important;
  border-top: 1px solid var(--color-light)!important;
  background: var(--color-light) !important;
  color: var(--color-dark) !important;
  font-weight: var(--main-font-weight) !important;
  font-size: 20px !important;
  line-height: 18px !important;
  background-origin: content-box !important;
  background-clip: padding-box !important;
}

.columnMonth :global(.ant-table-cell >tr > td) {
  /* border: 2px solid var(--color-bright-9) !important; */
}

.columnForBorder {
  border-right: 1px solid var(--color-light-2) !important;
}

.columnTotalInWort {
  border-right: 1px solid var(--color-light-2) !important;
  border-left: 1px solid var(--color-light-2) !important;
}

.table :global(.ant-table-tbody > tr > td) {
  padding: 10px 15px 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--color-additional-elements);
  width: 200px;
  white-space: nowrap;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.table :global(.ant-table-row td) {
  border-bottom: 1px solid var(--color-light-2);
}

.table :global(.ant-table-row):last-child {
  border-bottom: none;
}

.table :global(.ant-table-row):nth-child(odd) {
  background: var(--color-light-20);
}


.table :global(.ant-table-row):last-child {
  border-bottom: 1px solid var(--color-light-2);
}

.table :global(.ant-table table) {
  border-collapse: collapse;
}

.table :global(.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):hover) {
  background-color: transparent;
}

.table :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: transparent;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .table {
    padding-bottom: 30px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    height: 40px;
    font-size: 15px;

  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 17px;
    padding: 10px 10px;
  }

  .table :global(.ant-table-column-sorter-down){
    font-size: 18px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 18px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .table {
    padding-bottom: 10px;
    margin-top: 0px;

  }

  .table :global(.ant-table-tbody > tr > td) {
    height: 30px;
    padding: 4px 10px 6px 10px;
    font-size: 12px;
  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 14px;
    padding: 7px 7px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  .table :global(.ant-table-column-sorter-down){
    font-size: 15px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 15px;
  }

  .columnMonth {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .table {
    padding-bottom: 5px;
    border-radius: 5px;
    margin-top: 0;
  }

  .table :global(.ant-table-tbody > tr > td) {
    font-size: 6px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    height: 20px;
    padding: 2px;
  }

  .table :global(.ant-table-thead > tr > th) {
    font-size: 8px;
    padding: 0 5px;
    padding-bottom: 5px;
  }

  .table :global(.ant-table-column-sorter-down){
    font-size: 10px;
  }

  .table :global(.ant-table-column-sorter-up) {
    font-size: 10px;
  }

  .columnMonth {
    font-size: 10px !important;
    padding: 5px !important;
  }

  .wrap {
    padding: 5px;
  }

}



