.wrapper {
  padding: 0 40px 300px 40px;
  height: 100%;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-light);
  padding: 10px 40px 0px 40px !important;
}

.pagination {
  font-weight: var(--main-font-weight);
  font-size: 20px;
  line-height: 0px;
}

.formItem {
  display: flex;
  flex-direction: column;
  padding: 0 40px 5px 40px;
}

.formItem :global(.ant-form-item-label) {
  text-align: left;
  padding-bottom: 10px;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .header {
    padding: 10px 40px 0px 40px !important;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .header {
    padding: 10px 30px 0px 30px !important;
  }

  .wrapper {
    padding: 0 30px 270px 30px;
  }

  .formItem {
    padding: 0 30px 15px 30px;
  }

  .formItem :global(.ant-form-item-label) {
    height: 25px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .header {
    background-color: var(--color-light);
    padding: 10px 25px 0px 25px !important;
  }

  .wrapper {
    padding:0 25px 200px 25px;
  }

  .formItem {
    padding: 0 25px 10px 25px;
  }

  .formItem :global(.ant-form-item-label) {
    text-align: left;
    padding-bottom: 0;
    height: 15px;
  }

  .pagination {
    font-size: 10px;

  }
}