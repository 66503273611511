.uploadArea {
  width: 300px;
  height: 164px;
  padding: 5px 7px;
  background:var(--color-light);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  border: 2px dashed var(--color-additional-elements);
  color: var(--color-additional-elements);
  justify-content: center;
  cursor: pointer;
}
.container :global(.ant-spin-container){
  display: flex !important;
}
.uploadArea_disabled {
  background:var(--color-light-4);
  cursor: initial;
  border: 2px dashed var(--color-dark-2);
}

.uploadArea_disabled span {
  color: var(--color-dark-2) !important;
}

.uploadArea_disabled .icon svg {
  fill: var(--color-dark-2) !important;
}

.icon {
  font-size: 3.6em !important;
  margin-top: 8px;
}

.icon svg {
  fill: var(--color-additional-elements);
  width: 30px;
}

.title {
  font-weight: 600;
  font-size: 25px !important;
  line-height: 15px !important;
  margin-top: 5px !important;
}

.subTitle {
  font-weight: 400;
  font-size: 10px !important;
  margin-top: 2px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .uploadArea {
    width: 250px;
    height: 130px;
  }

  .icon {
    font-size: 3em !important;
  }

  .title {
    font-size: 20px !important;
    margin-top: 20px;
  }

}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .uploadArea {
    width: 200px;
    height: 100px;
  }

  .icon {
    font-size: 2.5em !important;
  }

  .title {
    font-size: 15px !important;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .uploadArea {
    width: 150px;
    height: 82px;
    margin-top: 7px;
  }

  .icon {
    font-size: 2em !important;
  }

  .icon svg {
    fill: var(--color-primary);
    width: 20px;
    height: 20px;
  }

  .title {
    font-size: 10px !important;
    margin-top: 5px;
  }
}
