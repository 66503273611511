.cardImage {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 15px;
  cursor: pointer;
}

.modalContent {
  margin-top: 80px;
}

.wrapImage {
  float: left;
  margin-right: 70px;
  margin-bottom: 37px;
}

.cardIcon {
  font-size: 2.8rem;
  align-self: center;
}

.wrapInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.carousel {
  width: 700px;
  height: 400px;
}

:global(.ant-carousel .slick-dots li button) {
  height: 7px;
  border-radius: 15px;
  background: var(--color-dark);
}

:global(.ant-carousel .slick-dots li.slick-active button) {
  background: var(--color-light);
}

:global(.ant-carousel .slick-dots li) {
  width: 34px;
}

:global(.ant-carousel .slick-dots li.slick-active) {
  width: 34px;
}

.titleFile {
  width: 70%;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: var( --color-dark-2);
}

.modal:global(.ant-modal){
  width: 74% !important;
  max-width: 74% !important;
}

.modal :global(.ant-modal-body) {
  min-height: 600px;
  max-height: 1200px;
  overflow: scroll;
  background-color: var(--color-light);
  box-shadow: 5px 5px 10px var(--color-light-4);
  border-radius: 15px;
}

:global(.ant-modal-wrap) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ant-modal) {
  top: 0;
}

.title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  color: var(--color-light);
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal :global(.ant-modal-title) {
  word-wrap: normal;
}

.modal :global(.ant-modal-header) {
  background-color:var(--color-primary);
  box-shadow: 5px 5px 20px 3px var(--color-light-4);
  border-radius: 15px;
  position: absolute;
  top: -20px;
  width: 100%;
  z-index: 100;
}

:global(.ant-modal-wrap) {
  background: transparent
}

.modal :global(.ant-modal-content) {
  background-color: transparent;
  box-shadow: none;
}

.modal :global(.ant-modal-close-x) {
  display: none;
}

.wrapCategory {
  display: flex;
}

.icon {
  font-size: 2.4rem;
  /*fill: var(--color-light);*/
  /*background-color: transparent;*/

}

.icon:hover {
  cursor: pointer;
}

.wrapTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category {
  border: 3px solid var(--color-dark-2);
  border-radius: 10px;
  color: var(--color-dark-2);
  padding: 9px 25px;
  font-size: 26px;
  margin-left: 12px;
  font-weight: 600;
  align-self: center;
}

.cardInfo {
  font-size: 26px;
  line-height: 34px;
  color: var(--color-dark);
}

@media screen and (max-width: 2559px) and (min-width: 1950px) {
  .carousel {
    width: 600px;
    height: 350px;
  }

  .category {
    font-size: 21px;
  }

  .cardIcon {
    font-size: 2rem;
  }

  .wrapImage {
    margin-right: 55px;
    margin-bottom: 20px;
  }

  .cardInfo {
    font-size: 21px;
  }

  .titleFile {
    font-size: 21px;
    line-height: 23px;
  }

  .title {
    font-size: 28px;
  }

  .modal :global(.ant-modal-body) {
    max-height: 700px;
    min-height: 350px;
  }

  .modalContent {
    margin-top: 70px;
  }

  .icon {
    font-size: 2rem;
  }

  .cardImage {
    height: 350px;
  }
}

@media screen and (max-width: 1949px) and (min-width: 1500px) {
  .wrapImage {
    margin-right: 25px;
    margin-bottom: 20px;
  }

  .cardImage {
    height: 250px;
  }

  .cardIcon {
    font-size: 1.3rem;
  }

  .cardInfo {
    font-size: 16px;
    line-height: 20px;
  }

  .titleFile {
    font-size: 16px;
    line-height: 20px;
  }

  .title {
    font-size: 23px;
  }

  .carousel {
    width: 500px;
    height: 250px;
  }

  .wrapInfo {
    margin-bottom: 25px;
  }

  .modal :global(.ant-modal-body) {
    max-height: 600px;
    min-height: 300px;
  }

  .modalContent {
    margin-top: 50px;
  }

  .icon {
    font-size: 1.8rem;
  }

  .modal :global(.ant-modal-header) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .category {
    font-size: 16px;
    border-width: 2px;
    padding: 4px 13px;
  }

  :global(.ant-carousel .slick-dots li button) {
    height: 4px;
  }
}

@media screen and (max-width: 1499px) and (min-width: 200px) {
  .wrapImage {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .cardIcon {
    font-size: 1rem;
  }

  .cardInfo {
    font-size: 11px;
    line-height: 15px;
  }

  .titleFile {
    font-size: 11px;
    line-height: 15px;
  }

  .title {
    font-size: 15px;
    line-height: 22px;
  }

  .carousel {
    width: 300px;
    height: 160px;
  }

  .category {
    border-width: 2px;
    padding: 3px 10px;
    font-size: 11px;
    border-radius: 5px;
    margin-left: 6px;
  }

  .wrapInfo {
    margin-bottom: 20px;
  }

  .modal :global(.ant-modal-body) {
    max-height: 500px;
    min-height: 250px;
  }

  .modal :global(.ant-modal-header) {
    padding-bottom: 9px;
    padding-top: 9px;
    border-radius: 7px;
  }

  .modalContent {
    margin-top: 15px;
  }

  .icon {
    font-size: 1.5rem;
  }

  .cardImage {
    height: 160px;
    border-radius: 7px;
  }

  :global(.ant-carousel .slick-dots li button) {
    height: 4px;
  }

  :global(.ant-carousel .slick-dots li) {
    width: 24px;
  }

  :global(.ant-carousel .slick-dots li.slick-active) {
    width: 24px;
  }

  :global(.ant-carousel .slick-dots li button) {
    height: 5px;
  }
}
